import {BLOCK_EXPLORER_URL, CHAIN_ID, CLIENT_ID, DISPLAY_NAME, RPC_PROVIDER_ADDRESS} from "../../../../utils/config";
import {CHAIN_NAMESPACES, WEB3AUTH_NETWORK} from "@web3auth/base";

const config = {
  clientId: CLIENT_ID,
  sessionTime: 3600, // 1 hour in seconds
  web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_MAINNET,
  chainConfig: {
    ticker: "MATIC",
    tickerName: "MATIC",
    displayName: DISPLAY_NAME,
    blockExplorerUrl: BLOCK_EXPLORER_URL,
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: CHAIN_ID,
    rpcTarget: RPC_PROVIDER_ADDRESS,
  },
};

export default config;