import { useContext, useEffect, useState } from "react";
import fon from "../assets/img/bg-grad-fat.svg";
import iconMenu from "../assets/img/icon/menu.svg";
import iconWallet from "../assets/img/icon/wallet.svg";
import iconMatic from "../assets/img/icon/crypto-logo.svg";
import iconTransfer from "../assets/img/icon/transfer.png";
import Button from "./Button";
import Logo from "./Logo";

import cls from "classnames";
import Menu from "./Menu";
import { lgContext } from "../app/providers/Providers";
import { language } from "../utils/language";
import useGetBalance from "../hooks/useGetBalance";

interface IProps {
  isConnected: boolean;
  openModalAuth: () => void;
  logout: () => void;
  openModalMoon: () => void;
  openModalTransfer: () => void;
}

export default function Header({
  isConnected,
  openModalAuth,
  logout,
  openModalMoon,
  openModalTransfer,
}: IProps) {
  const [balance, setBalance] = useState<number>(0);
  const [showMenu, setShowMenu] = useState(false);
  const { lg } = useContext(lgContext);

  const UpdateBalance = async () => {
    const balance = await useGetBalance();

    setBalance(balance);
  };

  useEffect(() => {
    if (isConnected) {
      UpdateBalance();
    }
  }, [isConnected]);

  return (
    <>
      <header
        className={cls(
          "flex justify-between items-center w-full border-b border-border bg-gradient-fat relative overflow-hidden",
          {
            "py-2 px-4": true,
            "md:pt-9 md:pb-3 md:px-4 md:h-[86px]": true,
          }
        )}
      >
        <img className="absolute scale-[5] -z-10" src={fon} alt="фон" />
        <Logo width="75" />
        <div className={cls("flex gap-2 content-start", {})}>
          {!isConnected ? (
            <Button onClick={openModalAuth} wallet>
              <img src={iconWallet} alt="wallet" />
              {language[lg].buttons.connect.toUpperCase()}
            </Button>
          ) :
            (
              <div className="flex items-center justify-between space-x-2 gap-1">
                <button
                  className="text-3xl text-white transition-all active:-rotate-180"
                  onClick={UpdateBalance}
                >
                  &#8634;
                </button>
                <img
                  className="h-3 w-3 relative top-[5px]"
                  src={iconMatic}
                  alt="matic"
                />
                <p className="items-center barlow-semi-condensed-bold text-3xl">
                  {balance ? Number(balance).toFixed(3) : 0}
                </p>
                <button className="h-6 w-6" onClick={openModalMoon}>
                  <img className="h-6 w-6" src={iconWallet} alt="wallet"/>
                </button>
                <button className="h-6 w-6" onClick={openModalTransfer}>
                  <img className="h-6 w-6" src={iconTransfer} alt="Transfer"/>
                </button>
              </div>
            )}
        </div>
        <div className="w-[75px] flex justify-end gap-1">
          {isConnected && (
            <button className="text-4xl px-1 text-white" onClick={logout}>
              &crarr;
            </button>
          )}
          <Button
            onClick={() => setShowMenu(!showMenu)}
            hidden="hidden md:block"
          >
            <img src={iconMenu} alt="menu" />
            {language[lg].buttons.menu.toUpperCase()}
          </Button>
          <button
            className="text-4xl px-1 text-white md:hidden"
            onClick={() => setShowMenu(!showMenu)}
          >
            &#8801;
          </button>
        </div>
      </header>
      <Menu open={showMenu} setOpen={setShowMenu} />
    </>
  );
}
