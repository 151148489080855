import ModalMoon from './modals/ModalMoon';
import ModalAuth from './modals/ModalAuth';
import Header from './Header';
import TeamDesktop from './teams/TeamDesktop';
import {avatars, betsList} from '../utils/constants';
import Panel from './information-panel/Panel';
import {ISettingsNotification, TSideTeam} from '../interfaces/interface';
import {makeTrade} from '../utils/contract';
import {ADDRESS_CONTRACT, POOL_ID} from '../utils/config';
import {useContext, useState} from 'react';
import MainBlock from '../components/main/Main';
import {logout} from '../app/web3/authPlatform';
import useModal from '../hooks/useModal';
import {Web3AuthContext} from '../app/providers/ProviderWeb3Auth';
import useGetBalance from "../hooks/useGetBalance";
import TransferModal from "./modals/TransferModal";
import NotificationModal from "./modals/NotificationModal";

function Main() {
  const { web3Auth, provider, isConnected} = useContext(Web3AuthContext);

  const [selectedBet, setSelectedBet] = useState<number>(0);
  const [selectedTeam, setSelectedTeam] = useState<TSideTeam | null>(null);

  const [isPlacedBet, setIsPlacedBet] = useState<boolean>(false);

  const [avatar] = useState<string | null>(
    avatars[String(Math.floor(Math.random() * 25)) as keyof typeof avatars]
  );
  const [disabledButton, setDisabledButton] = useState(false);
  const [settingsNotification, setSettingsNotification] = useState<ISettingsNotification>({message: '', typeMessage: 0});

  const {
    isOpen: isOpenModalAuth,
    openModalHandler: openModalAuth,
    closeModalHandler: closeModalAuth,
  } = useModal();

  const {
    isOpen: isOpenModalMoon,
    openModalHandler: openModalMoon,
    closeModalHandler: closeModalMoon,
  } = useModal();

  const {
    isOpen: isOpenModalTransfer,
    openModalHandler: openModalTransfer,
    closeModalHandler: closeModalTransfer,
  } = useModal();

  const {
    isOpen: isOpenModalNotification,
    openModalHandler: openModalNotification,
    closeModalHandler: closeModalNotification,
  } = useModal();

  // handleBet завязан на компоненты - оставить
  const handleBet = (bet: number, side?: TSideTeam) => {
    try {
      setSelectedBet(bet);
      if (side) setSelectedTeam(side);
    } catch (error: any) {
      //TODO добавить логирование
      console.error(error);
    }
  };

  // placeBet завязан на компоненты - оставить
  const placeBet = async (side: TSideTeam) => {
    if (!isConnected) openModalAuth();
    else if (web3Auth && web3Auth.connected && provider) {
      let successOperation = false;

      setSettingsNotification(
        {
          message: 'The operation is completed, wait ...',
          typeMessage: 2,
        }
      );
      openModalNotification();

      try {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const balance = await useGetBalance();

        if (Number(balance) < selectedBet) {
          openModalMoon()
        } else {
          const receipt = await makeTrade(
            provider,
            ADDRESS_CONTRACT,
            POOL_ID,
            avatar ?? 'avatarUrl',
            'countryCode',
            side === 'UP',
            'whiteLabelId',
            selectedBet
          );
          successOperation = true;

          setIsPlacedBet(successOperation);
        }
      } catch (error: any) {
        //TODO добавить логирование
        console.error(error);
      }

      setSettingsNotification(
        {
          message: successOperation ? 'You are in, good luck!' : 'Something is wrong!',
          typeMessage: Number(successOperation),
        }
      );
      openModalNotification();
    }
  };

  const handlerLogout = () => logout({ web3Auth });

  return (
    <>
      {isOpenModalMoon && <ModalMoon isOpen={isOpenModalMoon} closeModalMoon={closeModalMoon} />}
      {isOpenModalAuth && <ModalAuth closeModalAuth={closeModalAuth} />}
      {isOpenModalTransfer && <TransferModal open={isOpenModalTransfer} onClick={closeModalTransfer} />}
      <NotificationModal open={isOpenModalNotification} closeModalNotification={closeModalNotification} settings={settingsNotification} />
      <Header
        openModalAuth={openModalAuth}
        isConnected={isConnected}
        logout={handlerLogout}
        openModalMoon={openModalMoon}
        openModalTransfer={openModalTransfer}
      />
      <main className={'flex flex-grow w-full pb-2 md:px-4 max-h-[calc(100vh-56px)] md:max-h-[calc(100vh-86px)]'}>
        <TeamDesktop
          team='UP'
          placeBet={() => placeBet('UP')}
          bets={betsList}
          selectedBet={selectedBet}
          handleBet={handleBet}
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          isPlacedBet={isPlacedBet}
          disabledButton={disabledButton}
          setDisabledButton={setDisabledButton}
        />
        <div className={'flex flex-col flex-grow gap-1 sm:gap-2'}>
          <Panel
            selectedBet={selectedBet}
            isPlacedBet={isPlacedBet}
            setIsPlacedBet={setIsPlacedBet}
          />
          <MainBlock
            placeBet={placeBet}
            selectedBet={selectedBet}
            setSelectedBet={setSelectedBet}
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
            isPlacedBet={isPlacedBet}
            disabledButton={disabledButton}
            setDisabledButton={setDisabledButton}
          />
        </div>
        <TeamDesktop
          team='DOWN'
          placeBet={() => placeBet('DOWN')}
          bets={betsList}
          selectedBet={selectedBet}
          handleBet={handleBet}
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          isPlacedBet={isPlacedBet}
          disabledButton={disabledButton}
          setDisabledButton={setDisabledButton}
        />
      </main>
      {/* <p>{errorText}</p> */}
    </>
  )
}

export default Main;
