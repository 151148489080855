import {CLIENT_ID} from "../../../../utils/config";
import {UX_MODE} from "@web3auth/base";
import {privateKeyProvider} from "../../../../utils/web3AuthConfig";

const config = {
  adapterSettings: {
    clientId: CLIENT_ID,
    uxMode: UX_MODE.REDIRECT,
    // mfaSettings: {
    //   deviceShareFactor: {
    //     enable: true,
    //     priority: 1,
    //     mandatory: true,
    //   },
    //   backUpShareFactor: {
    //     enable: true,
    //     priority: 2,
    //     mandatory: false,
    //   },
    //   socialBackupFactor: {
    //     enable: true,
    //     priority: 3,
    //     mandatory: false,
    //   },
    //   passwordFactor: {
    //     enable: true,
    //     priority: 4,
    //     mandatory: false,
    //   },
    // },
  },
  loginSettings: {
    mfaLevel: "none",
  },
  privateKeyProvider,
};

export default config;
