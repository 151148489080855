
import { LoaderSpinner } from "./components/LoaderSpinner";
import {useContext, useEffect, useState} from "react";
import getAccounts from "./hooks/useGetAccounts";
import {setUser} from "./app/store/slices/userSlice";
import {setEarning} from "./app/store/slices/earningSlice";
import {useDispatch} from "react-redux";
import Web3Auth from "./app/web3";
import RPC from "./app/web3/RPC";
import Routes from "./app/routes/Routes";
import {Web3AuthContext} from "./app/providers/ProviderWeb3Auth";
import {IProvider} from '@web3auth/base';
import { viewProfile, getEarnings } from "./app/api/affiliate";
import { getStorage } from "./utils/localStorage";

declare global {
  interface Window {
    RPC?: any;
  }
}

function App() {
  const { setWeb3Auth, setWeb3Connected, setProvider, setWalletAddress } = useContext(Web3AuthContext);
  const [isLoading, setIsLoading] = useState<{ loadingProvider: boolean, forwardAccess: boolean } >({ loadingProvider: true, forwardAccess: true });
  const [provider, setProviderApp] = useState<IProvider | null>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (setWeb3Auth && setWeb3Connected) {
      Web3Auth({ setWeb3Connected })
        .then(async ({ web3Auth, provider }) => {
          setWeb3Auth(web3Auth);
          setProviderApp(provider);

          return await viewProfile();
        })
        .then((dataProfile) => {
          if (!dataProfile.data.success) {
            throw new Error('Error in view profile!');
          }

          dispatch(
            setUser(dataProfile.data.profile)
          );
        
          return getEarnings();
        })
        .then((dataGetEarnings) => {
          if (!dataGetEarnings.data.success) {
            throw new Error('Error in get earnings!');
          }

          dispatch(
            setEarning( {...dataGetEarnings.data} )
          );
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setIsLoading(({ loadingProvider }) => ({ loadingProvider, forwardAccess: false }));
        });
    }
  }, []);

  useEffect(() => {
    if (provider) {
      window.RPC = RPC(provider);
      setProvider && setProvider(provider);
      getAccounts();
      setWalletAddress && setWalletAddress(getStorage('wallet_connect'));
      setIsLoading(({ forwardAccess }) => ({ loadingProvider: false, forwardAccess }));
    }
  }, [provider]);

  return (
    (isLoading.forwardAccess || isLoading.loadingProvider)
      ? <LoaderSpinner isLoading={(isLoading.forwardAccess || isLoading.loadingProvider)} />
      : <Routes />
  );
}

export default App;
