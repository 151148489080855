import { BounceLoader } from "react-spinners";
import cls from "classnames";

interface IProps {
  isLoading: boolean
}

export const LoaderSpinner = ({ isLoading }: IProps) => {
  return (
    <div className={'fixed bg-menu-transparent right-0 h-full w-full z-30 flex flex-col items-center justify-center'}>
      <BounceLoader className="absolute top-[56px]" color="#91ff5d" loading={isLoading} size={50} />
    </div>
  );
};
