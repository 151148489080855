import { TSideTeam } from "../../interfaces/interface";
import BorderWrapper from "../BorderWrapper";
import TeamMobile from "./TeamMobile";

interface IProps {
  placeBet: (side: TSideTeam) => void;
  selectedBet: number | null;
  selectedTeam: TSideTeam | null;
  setSelectedTeam: React.Dispatch<React.SetStateAction<TSideTeam | null>>;
  isPlacedBet: boolean;
  disabledButton: boolean;
  setDisabledButton: (state: boolean) => void;
}

export default function WrapperTeamsMobile({
  placeBet,
  selectedBet,
  selectedTeam,
  setSelectedTeam,
  isPlacedBet,
  disabledButton,
  setDisabledButton,
}: IProps) {
  return (
    <div className="p-2 md:hidden">
      <BorderWrapper
        gradient="bg-border-teams"
        background="bg-teams-ml"
        column="flex-row"
      >
        <div className="w-1/2">
          <TeamMobile
            team="UP"
            placeBet={() => placeBet("UP")}
            selectedBet={selectedBet}
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
            isPlacedBet={isPlacedBet}
            disabledButton={disabledButton}
            setDisabledButton={setDisabledButton}
          />
        </div>
        <div className="w-[1px] bg-line-teams"></div>
        <div className="w-1/2">
          <TeamMobile
            team="DOWN"
            placeBet={() => placeBet("DOWN")}
            selectedBet={selectedBet}
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
            isPlacedBet={isPlacedBet}
            disabledButton={disabledButton}
            setDisabledButton={setDisabledButton}
          />
        </div>
      </BorderWrapper>
    </div>
  );
}
