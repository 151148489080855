import Avatar from "./Avatar";
import { IRoundPool, TSideTeam } from "../../interfaces/interface";
import { useContext } from "react";
import { poolInfoContext } from "../../app/providers/Providers";

interface IProps {
  team: TSideTeam;
}

export default function ListAvatarMobile({ team }: IProps) {
  const PoolInfo: IRoundPool = useContext(poolInfoContext);

  return (
    <div className="flex min-h-8">
      {team === "UP" &&
        PoolInfo.UpBetGroup.Avatars.map((avatar: string, index, players) => {
          let content = null;

          if (index < 8) {
            content = (
              <Avatar
                classes={index !== 0 ? "-ml-3.5" : ""}
                key={index}
                avatar={avatar}
                where="mobile"
                team={team}
              />
            );
          } else if (index === 8) {
            content = (
              <div
                key={index}
                className={`-ml-3.5 rounded-full backdrop-blur-[400px] min-w-8 h-8 overflow-hidden bg-body opacity-90`}
              >
                <div className="flex justify-center items-center min-w-8 h-8 backdrop-blur-xl  bg-blur rounded-full text-[10px]">
                  +{players.length - 8}
                </div>
              </div>
            );
          }

          return content;
        })}
      {team === "DOWN" &&
        PoolInfo.DownBetGroup.Avatars.map((avatar: string, index, players) => {
          let content = null;

          if (index < 8) {
            content = (
              <Avatar
                classes={index !== 0 ? "-ml-4" : ""}
                key={index}
                avatar={avatar}
                where="mobile"
                team={team}
              />
            )
          } else if (index === 8) {
            content = (
              <div
                key={index}
                className={`-ml-4 rounded-full backdrop-blur-[400px] min-w-8 h-8 overflow-hidden bg-body opacity-90`}
              >
                <div className="flex justify-center items-center min-w-8 h-8 backdrop-blur-xl  bg-blur rounded-full text-[10px]">
                  +{players.length - 8}
                </div>
              </div>
            );
          }

          return content;
        })}
    </div>
  );
}
