import cls from "classnames";

interface IProps {
  children: React.ReactNode;
  gradient?: string;
  padding?: string;
  background?: string;
  column?: string;
  full?: string;
  rounded?: string;
  classes?: string;
  overflow?: string;
  hidden?: boolean;
  borderBottom?: boolean;
}

export default function BorderWrapper({
  children,
  padding = "",
  gradient = "bg-border-main",
  background = "bg-main",
  column = "flex-col",
  full = "w-full h-full",
  rounded = "rounded-[20px]",
  classes = "",
  overflow = "overflow-hidden",
  hidden = false,
  borderBottom = false,
}: IProps) {
  return (
    <div
      className={cls(
        `${full} p-[0.5px] ${rounded} ${gradient} ${overflow} ${
          hidden && "hidden"
        }`,
        {
          hidden: hidden,
          "md:block": hidden,
          "pb-0": borderBottom,
        }
      )}
    >
      <div className={`bg-body ${full} ${rounded}`}>
        <div
          className={`relative flex ${classes} ${column} ${full} ${background} ${padding} ${rounded} backdrop-blur-[39px] ${overflow}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
