import cls from "classnames";
import { motion } from "framer-motion";
import {FieldValues, useForm} from "react-hook-form";
import {useDispatch} from 'react-redux';
import {createInviteLink, viewProfile} from '../../app/api/affiliate';
import {setUser} from "../../app/store/slices/userSlice";
import Input from "../form/Input";
import WhiteButton from "../WhiteButton";

interface FormState extends FieldValues{
  handle: string,
}

interface IProps {
  open: boolean,
  className?: string,
  onClick: () => void,
  setUpdateLink?: (state: string) => void,
}

const variantsOpen = {
  open: { translateY: 0 },
  closed: { translateY: -50 },
}
const variantsShow = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
}

const handleRegex = /^[a-zA-Z0-9]{3,15}$/;

function GeneralLinkModal({
  open = false,
  onClick,
  setUpdateLink,
  className = ''
}: IProps) {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
  } = useForm<FormState>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      handle: '',
    },
  });

  const dispatch = useDispatch();

  const submit = (formData: FormState) => {
    const data = {
      handle: formData.handle
    }

    createInviteLink(data)
      .then((response) => {
        // console.log('response create invite link: ', response.data);

        if (!response.data.success) {
          throw new Error('Error in create invite link!');
        }

        return viewProfile();
      })
      .then((response) => {
        // console.log('response view profile: ', response.data);

        if (!response.data.success) {
          throw new Error('Error in view profile!');
        }

        dispatch(
          setUser({...response.data.profile})
        );

        setUpdateLink && setUpdateLink(formData.handle);
      })
      .catch((e) => {
        console.log('Error: ', e);
      })
      .finally(reset);
  };

  const disabledSubmit = !Boolean(watch('handle').length);

  return (
    <motion.div
      className={cls(
        'fixed inset-0 bg-violet-600/40 px-[24px]',
        {'hidden': !open}
      )}
      animate={open ? "show" : "hidden"}
      variants={variantsShow}
    >
      <div
        className={cls(
          'fixed top-[50%] left-[50%]',
          'w-[calc(100%-32px)] lg:w-full max-w-[500px] md:w-[60%]',
          'translate-y-[-50%] translate-x-[-50%]'
        )}
      >
        <motion.div className={cls(
            'justify-center w-full h-full bg-black rounded-[10%]',
            'px-[24px] lg:px-[48px] pt-[16px] pb-[32px]',
            'border border-purple-600 border-2',
            className,
          )}
          animate={open ? "open" : "closed"}
          variants={variantsOpen}
          transition={{delay: .3, duration: .5}}
        >
          <motion.div
            className={cls('flex flex-col items-center')}
          >
            <h1 className='uppercase text-xl lg:text-3xl mb-[32px] lg:mb-[80px]'>Generate link</h1>
            <div className='flex flex-col item-center gap-x-[24px] w-full'>
              <Input
                className='mb-[32px]'
                label='Link name'
                name='handle'
                register={register}
                error={errors.handle}
                watch={watch}
                required={watch('handle')?.length ? 'Please set link name' : false}
                pattern={{ value: handleRegex, message: 'Incorrect link name' }}
              />
              <WhiteButton
                className='mt-[64px] mx-auto text-lg'
                onClick={handleSubmit(submit)}
                disabled={disabledSubmit}
              >
                submission
              </WhiteButton>
            </div>
            <button
              className={cls(
                'absolute top-[10px] right-0',
                'size-9 lg:size-12 bg-white rounded-full',
                'border-violet-900 border-2',
                'text-2xl text-violet-900 cursor-pointer',
                'hover:text-white hover:bg-black transition-all duration-300',
                'translate-x-[0%] translate-y-[-50%]'
              )}
              onClick={onClick}
            >X</button>
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  )
}

export default GeneralLinkModal;
