import {Route, Routes as Switch} from "react-router-dom";
import LoginPage from "../../pages/LoginPage";
import PrivateRoute from "../../pages/PrivateRouter";
import LinkManagerPage from "../../pages/LinkManagerPage";
import DashboardPage from "../../pages/DashboardPage";
import PayoutsPage from "../../pages/PayoutsPage";
import FAQPage from "../../pages/FAQPage";
import Main from "../../components/Main";

const Routes = () => {
  return (
      <Switch>
        <Route
          path='/login'
          element={<LoginPage />}
        />

        {/* private pages*/}
        <Route path="/user" element={<PrivateRoute />}>
          <Route
            path='link-manager'
            element={<LinkManagerPage />}
          />
          <Route
            path='dashboard'
            element={<DashboardPage />}
          />
          <Route
            path='payouts'
            element={<PayoutsPage />}
          />
          <Route
            path='faq'
            element={<FAQPage />}
          />
        </Route>

        <Route
          path='/'
          element={<Main />}
        />

        <Route
          path='/*'
          element={<Main />}
        />
      </Switch>
  )
}

export default Routes;
