import { EStatusText, IGameStatus } from "../interfaces/interface";
import avatar1 from "../assets/img/avatars/image 1.png";
import avatar2 from "../assets/img/avatars/image 2.png";
import avatar3 from "../assets/img/avatars/image 3.png";
import avatar4 from "../assets/img/avatars/image 4.png";
import avatar5 from "../assets/img/avatars/image 5.png";
import avatar6 from "../assets/img/avatars/image 6.png";
import avatar7 from "../assets/img/avatars/image 7.png";
import avatar8 from "../assets/img/avatars/image 8.png";
import avatar9 from "../assets/img/avatars/image 9.png";
import avatar10 from "../assets/img/avatars/image 10.png";
import avatar11 from "../assets/img/avatars/image 11.png";
import avatar12 from "../assets/img/avatars/image 12.png";
import avatar13 from "../assets/img/avatars/image 13.png";
import avatar14 from "../assets/img/avatars/image 14.png";
import avatar15 from "../assets/img/avatars/image 15.png";
import avatar16 from "../assets/img/avatars/image 16.png";
import avatar17 from "../assets/img/avatars/image 17.png";
import avatar18 from "../assets/img/avatars/image 18.png";
import avatar19 from "../assets/img/avatars/image 19.png";
import avatar20 from "../assets/img/avatars/image 20.png";
import avatar21 from "../assets/img/avatars/image 21.png";
import avatar22 from "../assets/img/avatars/image 22.png";
import avatar23 from "../assets/img/avatars/image 23.png";
import avatar24 from "../assets/img/avatars/image 24.png";
import avatar25 from "../assets/img/avatars/image 25.png";

// export const betsList = [10, 20, 50, 100, 200, 500];
export const betsList = [0.0001, 0.0002, 0.0005, 0.001];

export const avatars = {
  "1": avatar1,
  "2": avatar2,
  "3": avatar3,
  "4": avatar4,
  "5": avatar5,
  "6": avatar6,
  "7": avatar7,
  "8": avatar8,
  "9": avatar9,
  "10": avatar10,
  "11": avatar11,
  "12": avatar12,
  "13": avatar13,
  "14": avatar14,
  "15": avatar15,
  "16": avatar16,
  "17": avatar17,
  "18": avatar18,
  "19": avatar19,
  "20": avatar20,
  "21": avatar21,
  "22": avatar22,
  "23": avatar23,
  "24": avatar24,
  "25": avatar25,
};

export const initialData: IGameStatus = {
  PoolInfo: {
    Created: true,
    StartPrice: 0,
    EndPrice: 0,
    MinBetAmount: 0,
    MaxBetAmount: 1000000000000000000000,
    PoolBetsLimit: 100,
    UpBetGroup: {
      Bets: [],
      Addresses: [],
      Avatars: [],
      Countries: [],
      WhiteLabelIds: [],
      Total: 0,
      DistributedCount: 0,
      TotalDistributed: 0,
    },
    DownBetGroup: {
      Bets: [],
      Addresses: [],
      Avatars: [],
      Countries: [],
      WhiteLabelIds: [],
      Total: 0,
      DistributedCount: 0,
      TotalDistributed: 0,
    },
    RoundStartTime: 1712964171000000,
    TradesStartTimeMS: 1712964186,
    TradesEndTimeMS: 1713568986,
  },
  PriceInfo: {
    lastPrice: 671148170,
    lastTime: "Apr 12, 2024 11:45:29 PM",
    lastTimeMs: 1712965529400000,
  },
  InternalInfo: {
    EnableTrade: true,
    Time2End: 0,
    Text: EStatusText.Open,
  },
  JackpotInfo: {
    JackpotBalance: 0,
  },
  NewResult: {
    StartPrice: 0,
    EndPrice: 0,
    Wins: null,
    Hash: "",
    Timestamp: 0,
  },
  LastResults: {
    Data: [],
  },
};
