import { ethers } from "ethers";

export const contractABI = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "BetAmountZero",
    type: "error",
  },
  {
    inputs: [],
    name: "InsufficientBalance",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "enum Fomo.GameState",
        name: "expectedState",
        type: "uint8",
      },
      {
        internalType: "enum Fomo.GameState",
        name: "currentState",
        type: "uint8",
      },
    ],
    name: "InvalidState",
    type: "error",
  },
  {
    inputs: [],
    name: "OnlyAdmin",
    type: "error",
  },
  {
    inputs: [],
    name: "TransferFailed",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "player",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "bool",
        name: "isUp",
        type: "bool",
      },
    ],
    name: "BetPlaced",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "player",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "bool",
        name: "isUp",
        type: "bool",
      },
    ],
    name: "BetWithdrawn",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "btcPrice",
        type: "uint256",
      },
    ],
    name: "BettingEnded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "btcPrice",
        type: "uint256",
      },
    ],
    name: "BettingStarted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bool",
        name: "isUp",
        type: "bool",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "totalPayout",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "btcPrice",
        type: "uint256",
      },
    ],
    name: "WinnerDetermined",
    type: "event",
  },
  {
    stateMutability: "payable",
    type: "fallback",
  },
  {
    inputs: [],
    name: "FEE",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "bets",
    outputs: [
      {
        internalType: "address",
        name: "player",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "isUp",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "currentState",
    outputs: [
      {
        internalType: "enum Fomo.GameState",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bool",
        name: "isUp",
        type: "bool",
      },
      {
        internalType: "uint256",
        name: "_btcPrice",
        type: "uint256",
      },
    ],
    name: "determineWinner",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_btcPrice",
        type: "uint256",
      },
    ],
    name: "endBetting",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getBets",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "player",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "isUp",
            type: "bool",
          },
        ],
        internalType: "struct Fomo.Bet[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bool",
        name: "isUp",
        type: "bool",
      },
    ],
    name: "placeBet",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_btcPrice",
        type: "uint256",
      },
    ],
    name: "startBetting",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "teamDown",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "teamUp",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalDown",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalUp",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "withdrawBet",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    stateMutability: "payable",
    type: "receive",
  },
];
// TODO
export const contractAddress = "0x281d56e015cb23cbb84fd9cc6b5a5faa877f9e25";

export async function makeTrade(
  provider: any,
  gameAddress: string,
  poolId: any,
  avatarUrl: string,
  countryCode: any,
  upOrDown: any,
  whiteLabelId: any,
  amountMatic: any
) {
  if (provider) {
    const providerETH = new ethers.BrowserProvider(provider);
    const signer = await providerETH.getSigner();

    const gameContract = new ethers.Contract(gameAddress, contractABI, signer);

    const amountWei = ethers.parseEther(amountMatic.toString());

    const tradeStruct = {
      poolId: poolId,
      avatarUrl,
      countryCode,
      upOrDown,
      whiteLabelId,
    };
    const makeTradeTx = await gameContract.placeBet(upOrDown, {
      value: amountWei,
    });
    console.log(makeTradeTx);
    await makeTradeTx.wait();
  }
}
