import cls from "classnames";
import { NavLink } from "react-router-dom";

interface IProps {
  onClick?: (someVar: any) => void;
  active?: boolean,
  disabled?: boolean,
  big?: boolean,
  children: React.ReactNode;
  className?: string,
  isNavLink?: boolean,
  to?: string,
}

function WhiteButton({
  to = '',
  isNavLink = false,
  onClick,
  disabled = false,
  big = true,
  children,
  className = '',
}: IProps ) {

  const classNameButton = cls(
      'flex items-center justify-center text-xs lg:text-sm h-max',
      'border border-2 lg:border-4 w-max w-full rounded-lg text-violet-600 p-[8px] lg:p-[12px]',
      'transition-all duration-300',
      'hover:border-white',
      className,
      { 'bg-gray-300': disabled },
      { 'bg-white hover:text-white hover:bg-violet-900': !disabled },
      { 'lg:min-w-[200px] font-bold': big }
  )
  return (
      isNavLink ? (
          <NavLink
            to={to}
            className={({ isActive }) => (isActive ? `border-pink-600  ${classNameButton}` : `border-transparent ${classNameButton}`)}
          >
            {children}
          </NavLink>
          ) : (
        <button
          className={classNameButton}
          onClick={onClick}
          disabled={disabled}
        >
          {children}
        </button>
      )

  )
}

export default WhiteButton;
