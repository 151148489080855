import {OpenloginAdapter} from "@web3auth/openlogin-adapter";
import config from "./config";

const Adapter = (web3auth: { configureAdapter: (arg0: OpenloginAdapter) => void; }) => {
  // @ts-ignore
  const openloginAdapter = new OpenloginAdapter(config);
  web3auth.configureAdapter(openloginAdapter);
}

export default Adapter;
