import config from "./config";
import {Web3AuthNoModal} from "@web3auth/no-modal";

interface ILogin {
  web3Auth: Web3AuthNoModal | null,
  platform: string,
}

const Login = async ({ web3Auth, platform }: ILogin) => {
  if (web3Auth) {
    const params = config[platform];
    return await web3Auth
      // @ts-ignore
      .connectTo(...params)
  }
};

export default Login;
