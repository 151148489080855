import HeaderAccount from '../components/HeaderAccount';

import {motion} from 'framer-motion';
import Table from "../components/table/Table";
import {useState} from "react";
import GeneralLinkModal from "../components/modals/GeneralLinkModal";
import MobileMenuPages from "../components/MobileMenuPages";
import RequestPayoutModal from "../components/modals/RequestPayoutModal";
import WhiteButton from "../components/WhiteButton";

function PayoutsPage() {
  const [openGeneralLinkModal, setOpenGeneralLinkModal] = useState(false);
  const [openPayoutsModal, setOpenPayoutsModal] = useState(false);

  const handlerOpenModalGeneralLink = () => {
    setOpenGeneralLinkModal((prev) => !prev)
  }
  const handlerOpenPayoutsModal = () => {
    setOpenPayoutsModal((prev) => !prev)
  }

  return (
    <div className='flex flex-col pt-[48px] h-full w-full bg-bg-violet-gradient bg-no-repeat bg-cover'>
      <motion.div
        className='flex flex-col items-center bg-bg-violet w-full mb-[64px] px-[16px] lg:px-[32px]'
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{delay: .5, duration: .5}}
      >
        <HeaderAccount
          handlerOpenModalGeneralLink={handlerOpenModalGeneralLink}
        >
          <WhiteButton
            onClick={handlerOpenPayoutsModal}
          >
            Request payout
          </WhiteButton>
        </ HeaderAccount>
        <h1 className='text-4xl md:text-6xl mb-[16px] md:mb-[32px] lg:mb-[48px]'>Payouts</h1>
        <Table
          headers={[
            'Date',
            'Amount',
            'Status',
          ]}
          rows={[]}
        />

      </motion.div>
      <GeneralLinkModal
        open={openGeneralLinkModal}
        onClick={handlerOpenModalGeneralLink}
      />
      <RequestPayoutModal
        open={openPayoutsModal}
        onClick={handlerOpenPayoutsModal}
      />
      <MobileMenuPages />
    </div>
  )
}

export default PayoutsPage;
