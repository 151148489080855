//@ts-nocheck
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { useContext, useRef } from "react";
import { Line } from "react-chartjs-2";
import { backgroundSplitPlugin } from "./backgroundSplitPlugin";
import { getOptions } from "./getOptions";
import useChartData from "../../../hooks/useChartData";
import { IPrice } from "../../../interfaces/interface";
import { startPriceContext } from "../../../app/providers/Providers";
import { showTooltip } from "./showTooltip";
import { startTooltip } from "./startTooltip";
import Info from "./Info";

ChartJS.register(
  backgroundSplitPlugin,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const Chart = () => {
  const { chartData, lockValue } = useChartData();

  const startPrice = useContext<IPrice>(startPriceContext);

  const options = getOptions(
    lockValue,
    chartData.datasets[0].data[89],
    startPrice / 10000
  );

  const chartRef = useRef<any>(null);

  return (
    <div className="relative overflow-hidden flex-grow block">
      <Info />
      <Line
        ref={chartRef}
        className="z-10"
        options={options}
        plugins={[showTooltip, startTooltip]}
        data={chartData}
      />
    </div>
  );
};

export default Chart;
