import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ErrorBoundary } from "./components/ErrorBondry";
import { Provider } from 'react-redux';
import store from './app/store/';
import {BrowserRouter} from 'react-router-dom';
import Providers from "./app/providers/Providers";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <Providers>
        <BrowserRouter>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </BrowserRouter>
    </Providers>
  </Provider>
);
