import HeaderAccount from '../components/HeaderAccount';

import { motion } from 'framer-motion';
import { useState } from "react";
import MobileMenuPages from "../components/MobileMenuPages";
import GeneralLinkModal from "../components/modals/GeneralLinkModal";

function FAQPage() {
  const [openGeneralLinkModal, setOpenGeneralLinkModal] = useState(false);

  const handlerOpenModalGeneralLink = () => {
    setOpenGeneralLinkModal((prev) => !prev)
  }

  return (
    <div className='flex flex-col pt-[48px] h-full w-full bg-bg-violet-gradient bg-no-repeat bg-cover'>
      <motion.div
        className='flex flex-col items-center bg-bg-violet w-full mb-[64px] px-[16px] lg:px-[32px]'
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{delay: .5, duration: .5}}
      >
        <HeaderAccount
          handlerOpenModalGeneralLink={handlerOpenModalGeneralLink}
        />
        <h1 className='text-4xl md:text-6xl mb-[16px] md:mb-[32px] lg:mb-[48px]'>FAQ</h1>
        <div
          className='flex flex-col gap-y-[32px] w-full'>
          <p className='text-lg'><b>About the affiliate program</b></p>
          <p className='text-lg'><b>How do I become a partner</b></p>
          <p className='text-lg'><b>What commission can I earn as a partner?</b></p>
        </div>
      </motion.div>
      <GeneralLinkModal
        open={openGeneralLinkModal}
        onClick={handlerOpenModalGeneralLink}
      />
      <MobileMenuPages/>
    </div>
  )
}

export default FAQPage;
