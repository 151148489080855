import LoginForm from "../components/form/LoginForm";
import { motion } from 'framer-motion';
import {useContext, useEffect, useState} from 'react';
import ModalAuth from "../components/modals/ModalAuth";
import useModal from "../hooks/useModal";
import {Web3AuthContext} from "../app/providers/ProviderWeb3Auth";
import getAccounts from "../hooks/useGetAccounts";
import {logout} from "../app/web3/authPlatform";
import ModalMoon from "../components/modals/ModalMoon";
import Logo from "../components/Logo";
import Button from "../components/Button";
import iconMenu from "../assets/img/icon/menu.svg";
import {language} from "../utils/language";
import Menu from "../components/Menu";
import {lgContext} from "../app/providers/Providers";

function LoginPage() {
  const { web3Auth, isConnected} = useContext(Web3AuthContext);
  const [showMenu, setShowMenu] = useState(false);
  const { lg } = useContext(lgContext);

  const {
    isOpen: isOpenModalAuth,
    openModalHandler: openModalAuth,
    closeModalHandler: closeModalAuth,
  } = useModal();

  const {
    isOpen: isOpenModalMoon,
    closeModalHandler: closeModalMoon,
  } = useModal();

  const {
    isOpen: isOpenModalTransfer,
    closeModalHandler: closeModalTransfer,
  } = useModal();

  const handlerLogout = () => logout({ web3Auth });

  useEffect(() => {
    getAccounts()
  }, []);

  return (
    <>
      <header className="flex justify-between items-center w-full border-b border-border relative overflow-hidden py-2 px-4 md:pt-9 md:pb-3 md:px-4">
        <Logo width="75"/>
        <div className="w-[75px] flex justify-end gap-1">
          {isConnected && (
            <button className="text-4xl px-1 text-white" onClick={handlerLogout}>
              &crarr;
            </button>
          )}
          <Button
            onClick={() => setShowMenu(!showMenu)}
            hidden="hidden md:block"
          >
            <img src={iconMenu} alt="menu"/>
            {language[lg].buttons.menu.toUpperCase()}
          </Button>
          <button
            className="text-4xl px-1 text-white md:hidden"
            onClick={() => setShowMenu(!showMenu)}
          >
            &#8801;
          </button>
        </div>
      </header>
      <div className='relative flex justify-center h-full w-full bg-bg-violet-gradient bg-no-repeat bg-cover'>
        <motion.div
          className='absolute top-[50%] flex flex-col items-center bg-bg-violet h-full w-full px-[24px] lg:px-[48px] translate-y-[-32%]'
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          transition={{delay: .5, duration: .5}}
        >
          <h1 className='uppercase text-center mb-[64px] lg:mb-[80px] text-2xl lg:text-5xl'>referal program link
            manager</h1>
          <motion.div
            className='flex flex-col items-center'
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{delay: 1, duration: .5}}
          >
            <h3 className='text-xl lg:text-3xl mb-[32px] lg:mb-[64px]'>Sing up / Sing in</h3>
            <LoginForm isConnected={isConnected} openModalAuth={openModalAuth}/>
          </motion.div>
        </motion.div>
      </div>
      {isOpenModalAuth && <ModalAuth closeModalAuth={closeModalAuth}/>}
      {isOpenModalMoon && <ModalMoon isOpen={isOpenModalMoon} closeModalMoon={closeModalMoon} />}
      {isOpenModalTransfer && <ModalMoon isOpen={isOpenModalTransfer} closeModalMoon={closeModalTransfer} />}
      {showMenu && <Menu open={showMenu} setOpen={setShowMenu} />}
    </>
  )
}

export default LoginPage;
