import { motion } from "framer-motion";
import RowTable from "./RowTable";
import cls from "classnames";

interface IProps {
  headers: string[],
  className?: string,
}

function HeaderTable({
  className,
  headers
}: IProps) {
  return (
    <motion.div
      className={
        cls(
        'flex justify-between gap-x-[16px] py-[12px] w-[90%]',
          'relative mb-[16px]',
          'before:block before:absolute before:bottom-0 before:right-0 before:left-0',
          'before:content-[""] before:lg:w-[calc(100%))] before:h-[2px] before:bg-white before:border-b-2',
          className
        )
      }
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: .2, duration: .8 }}
    >
      {headers.map((item, index) => (
        <p
          key={item + index}
          className='text-center text-xs sm:text-sm md:text-xl w-[20%]'>
          {item}
        </p>
      ))}
    </motion.div>
  )
}
export default HeaderTable;
