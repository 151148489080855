import { toBigInt } from 'ethers';

export const uInt256ToString = (value: number) => toBigInt(value).toString();

export const getTime = (sec: number) => {
  const secStr = sec < 10 ? `0${sec}` : sec > 30 ? `+30` : `${sec}`;
  return `${secStr}:00`;
};

export const getSumWei = (sum: number) => {
  if (!sum) return 0;
  return sum / 10 ** 18;
};

export const getPoolPayout = (
  total1: number,
  total2: number,
  fee: number,
  type?: string
): number => {
  if ((!total1 || !total2) && type !== "kof") return 200;
  if (!total1 || !total2) return 0;

  const kof = 1 + total1 / total2 - fee / 100;

  return type === "kof" ? kof : Number((kof * 100).toFixed(0));
};

export const getRandomOnline = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getDataForInviteeLink = () => {
  const walletRegex = /^0x[0-9a-zA-Z]{2,}$/;
  const { pathname, search } = window.location;

  const params = new URLSearchParams(search);
  const ref = params.get('ref')

  const isCheck = ref && ref.match(walletRegex) && ref.length === 42;

  return { handle: pathname.replace(/\//g, ''), invitee_address: isCheck ? ref : '' };
}

export const formingCurrentNumberEarnings = (someNumber :string) => (Number(someNumber) / (10 ** 18)).toPrecision();

