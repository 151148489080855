import {getWalletConnectV2Settings, WalletConnectV2Adapter} from "@web3auth/wallet-connect-v2-adapter";
import {CHAIN_NAMESPACES} from "@web3auth/base";
import {WalletConnectModal} from "@walletconnect/modal";
import {CHAIN_ID, WALLET_CONNECT_PROJECT_ID} from "../../../../utils/config";

const Adapter = async (web3auth: { configureAdapter: (arg0: WalletConnectV2Adapter) => void }) => {
  // adding wallet connect v2 adapter
  const { adapterSettings, loginSettings } = await getWalletConnectV2Settings(
    CHAIN_NAMESPACES.EIP155,
    [CHAIN_ID],
    WALLET_CONNECT_PROJECT_ID
  );
  const walletConnectModal = new WalletConnectModal({
    projectId: WALLET_CONNECT_PROJECT_ID,
  });
  const walletConnectV2Adapter = new WalletConnectV2Adapter({
    adapterSettings: {
      qrcodeModal: walletConnectModal,
      ...adapterSettings,
    },
    loginSettings: { ...loginSettings },
  });

  web3auth.configureAdapter(walletConnectV2Adapter);
}

export default Adapter;
