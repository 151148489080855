import { useContext } from "react";
import { IRoundPool, TSideTeam } from "../../interfaces/interface";
import Avatar from "./Avatar";

import cls from "classnames";
import { poolInfoContext } from "../../app/providers/Providers";

interface IProps {
  team: TSideTeam;
}

export default function ListAvatarDesctop({ team }: IProps) {
  const PoolInfo = useContext<IRoundPool>(poolInfoContext);

  return (
    <div
      className={cls(
        "flex flex-row flex-grow gap-2 flex-wrap p-2 overflow-hidden content-start",
        {
          "justify-end": team === "DOWN",
        }
      )}
    >
      {team === "UP" &&
        PoolInfo.UpBetGroup.Avatars.map((avatar, index) => (
          <Avatar
            key={index}
            where="desktop"
            avatar={avatar}
            team={team}
          />
        ))}

      {team === "DOWN" &&
        PoolInfo.DownBetGroup.Avatars.map((avatar, index) => (
          <Avatar
            key={index}
            where="desktop"
            avatar={avatar}
            team={team}
          />
        ))}
    </div>
  );
}
