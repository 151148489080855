import React, { createContext, useEffect, useMemo, useState } from "react";
import {
  ELanguage,
  IInternalInfo,
  IJackpotInfo,
  IPrice,
  ILastResults,
  IResultGame,
  IRoundPool,
  ILgState,
} from "../../interfaces/interface";
import { initialData } from "../../utils/constants";
import { urlWebSocket } from "../../utils/config";
import ProviderWeb3Auth from './ProviderWeb3Auth';

export const lgContext = createContext<ILgState>({
  lg: ELanguage.en,
  setLg: () => {},
});

export const poolInfoContext = createContext<IRoundPool>(initialData.PoolInfo);
export const internalInfoContext = createContext<IInternalInfo>(
  initialData.InternalInfo
);
export const jackpotInfoContext = createContext<IJackpotInfo>(
  initialData.JackpotInfo
);
export const newResultContext = createContext<IResultGame>(
  initialData.NewResult
);
export const lastResultsContext = createContext<ILastResults>(
  initialData.LastResults
);
export const lastPriceContext = createContext<IPrice>(
  initialData.PriceInfo.lastPrice
);
export const startPriceContext = createContext<IPrice>(
  initialData.PoolInfo.StartPrice
);

export const historyContext = createContext<any[]>([]);

const gameSocket = new WebSocket(urlWebSocket);

export default function Providers({ children }: { children: React.ReactNode }) {
  const [gameHistory, setGameHistory] = useState<any[]>([]);
  const langStorage = localStorage.getItem("lang");
  const [lg, setLg] = useState<ELanguage>(
    langStorage ? (langStorage as ELanguage) : ELanguage.en
  );

  const [PoolInfo, setPoolInfo] = useState<IRoundPool>(initialData.PoolInfo);
  const [InternalInfo, setInternalInfo] = useState<IInternalInfo>(
    initialData.InternalInfo
  );
  const [JackpotInfo, setJackpotInfo] = useState<IJackpotInfo>(
    initialData.JackpotInfo
  );
  const [NewResult, setNewResult] = useState<IResultGame>(
    initialData.NewResult
  );
  const [LastResults, setLastResults] = useState<ILastResults>(
    initialData.LastResults
  );

  const [lastPrice, setLastPrice] = useState<IPrice>(
    initialData.PriceInfo.lastPrice
  );
  const [startPrice, setStartPrice] = useState<IPrice>(
    initialData.PoolInfo.StartPrice
  );

  const PoolInfoValue = useMemo(() => PoolInfo, [PoolInfo]);
  const InternalInfoValue = useMemo(() => InternalInfo, [InternalInfo]);
  const JackpotInfoValue = useMemo(() => JackpotInfo, [JackpotInfo]);
  const NewResultValue = useMemo(() => NewResult, [NewResult]);
  const LastResultsValue = useMemo(() => LastResults, [LastResults]);

  const LastPriceValue = useMemo(() => lastPrice, [lastPrice]);
  const StartPriceValue = useMemo(() => startPrice, [startPrice]);

  useEffect(() => {
    gameSocket.onopen = () => {
      console.log("game socket connected");
    };
    gameSocket.onclose = (event) => {
      if (event.wasClean) {
        console.log("Соединение закрыто чисто");
      } else {
        console.log("Обрыв соединения"); // например, "убит" процесс сервера
      }

      console.log("Код: " + event.code + " причина: " + event.reason);
      setTimeout(window.location.reload, 5000);
    };
    gameSocket.onmessage = (event) => {
      const res = JSON.parse(event.data);

      switch (res.MessageType) {
        case "GameStatus":
          setPoolInfo(res.Data.PoolInfo);
          setLastPrice(res.Data.PriceInfo.lastPrice);
          setInternalInfo(res.Data.InternalInfo);
          setJackpotInfo(res.Data.JackpotInfo);
          setNewResult(res.Data.NewResult);
          setLastResults(res.Data.LastResults);
          setStartPrice(res.Data.PoolInfo.StartPrice);
          break;
        case "PriceHistory":
          setGameHistory(res.Data);
          break;

        default:
          break;
      }
    };
    gameSocket.onerror = (event) => {
      console.log("game socket error: ", event);
    };
  }, []);

  return (
    <ProviderWeb3Auth>
      <lgContext.Provider value={{ lg, setLg }}>
        <historyContext.Provider value={gameHistory}>
          <poolInfoContext.Provider value={PoolInfoValue}>
            <lastPriceContext.Provider value={LastPriceValue}>
              <startPriceContext.Provider value={StartPriceValue}>
                <internalInfoContext.Provider value={InternalInfoValue}>
                  <jackpotInfoContext.Provider value={JackpotInfoValue}>
                    <newResultContext.Provider value={NewResultValue}>
                      <lastResultsContext.Provider value={LastResultsValue}>
                        {children}
                      </lastResultsContext.Provider>
                    </newResultContext.Provider>
                  </jackpotInfoContext.Provider>
                </internalInfoContext.Provider>
              </startPriceContext.Provider>
            </lastPriceContext.Provider>
          </poolInfoContext.Provider>
        </historyContext.Provider>
      </lgContext.Provider>
    </ProviderWeb3Auth>
  );
}
