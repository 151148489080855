import cls from "classnames";
import { motion } from "framer-motion";
import {FieldValues, useForm} from "react-hook-form";
import Input from "../form/Input";
import WhiteButton from "../WhiteButton";
import {requestPayout} from "../../app/api/affiliate";

interface FormState extends FieldValues{
  amount: number,
}

interface IProps {
  open: boolean,
  className?: string,
  onClick: () => void,
}

const variantsOpen = {
  open: { translateY: 0 },
  closed: { translateY: -50 },
}
const variantsShow = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
}

const amountRegex = /^\d+(?:[\.,]\d+)?$/;

function RequestPayoutModal({
  open = false,
  onClick,
  className = ''
}: IProps) {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
  } = useForm<FormState>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      amount: 0,
    },
  });

  const submit = (formData: FormState) => {
    const data = {
      amount: formData.amount
    }

    requestPayout(data)
      .then((response) => {
        const {success, message, payoutAmount} = response.data;

        if (!success) {
          throw new Error('Error in request payout!');
        }

        alert(`${message} ${payoutAmount}`);
        // console.log('Request set amount! Data:', response.data);
      })
      .catch((error) => {
        console.log('Error: ', error);

        alert(error.response.data.error)
      })
      .finally(reset);
  };

  const disabledSubmit = !Boolean(watch('amount') > 0);

  return (
    <motion.div
      className={cls(
        'fixed inset-0 bg-violet-600/40 px-[24px]',
        {'hidden': !open}
      )}
      animate={open ? "show" : "hidden"}
      variants={variantsShow}
    >
      <div
        className={cls(
          'fixed top-[50%] left-[50%]',
          'w-[calc(100%-32px)] lg:w-full max-w-[500px] md:w-[60%]',
          'translate-y-[-50%] translate-x-[-50%]'
        )}
      >
        <motion.div className={cls(
            'justify-center w-full h-full bg-black rounded-[10%]',
            'px-[24px] lg:px-[48px] pt-[16px] pb-[32px]',
            'border border-purple-600 border-2',
            className,
          )}
          animate={open ? "open" : "closed"}
          variants={variantsOpen}
          transition={{delay: .3, duration: .5}}
        >
          <motion.div
            className={cls('flex flex-col items-center')}
          >
            <h1 className='uppercase text-xl lg:text-3xl mb-[32px] lg:mb-[80px]'>Amount</h1>
            <div className='flex flex-col item-center gap-x-[24px] w-full'>
              <Input
                className='mb-[32px]'
                label='Amount'
                name='amount'
                register={register}
                error={errors.amount}
                watch={watch}
                required={watch('amount') ? 'Please set amount' : false}
                pattern={{ value: amountRegex, message: 'Incorrect amount value' }}
              />
              <WhiteButton
                className='mt-[64px] mx-auto text-lg'
                onClick={handleSubmit(submit)}
                disabled={disabledSubmit}
              >
                Request
              </WhiteButton>
            </div>
            <button
              className={cls(
                'absolute top-[10px] right-0',
                'size-9 lg:size-12 bg-white rounded-full',
                'border-violet-900 border-2',
                'text-2xl text-violet-900 cursor-pointer',
                'hover:text-white hover:bg-black transition-all duration-300',
                'translate-x-[0%] translate-y-[-50%]'
              )}
              onClick={onClick}
            >X</button>
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  )
}

export default RequestPayoutModal;
