import CoinbaseAdapter from './coinbase'
import MetamaskAdapter from './metamask'
import OpenLoginAdapter from './openlogin';
import WalletConnectAdapter from './walletConnect';

const Adapters = async (web3auth: any) => {
  await Promise.resolve()
    .then(() => {
      CoinbaseAdapter(web3auth);
    })
    .then(() => {
      MetamaskAdapter(web3auth);
    })
    .then(() => {
      OpenLoginAdapter(web3auth);
    })
    .then(async () => {
      await WalletConnectAdapter(web3auth);
    })
}

export default Adapters;
