import { useContext } from "react";
import { lgContext } from "../app/providers/Providers";
import { ELanguage } from "../interfaces/interface";
import Button from "./Button";

import backIcon from "../assets/img/icon/back-arrow-white-icon.png";

export default function MenuLg({
  setOpen,
  setMenuStage,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMenuStage: React.Dispatch<React.SetStateAction<string>>;
}) {
  const { setLg } = useContext(lgContext);

  const lgMenu = [
    {
      name: "English",
      code: ELanguage.en,
    },
    {
      name: "Русский",
      code: ELanguage.ru,
    },
    {
      name: "Español",
      code: ELanguage.es,
    },
    {
      name: "Portugues",
      code: ELanguage.pt,
    },
    {
      name: "Greek",
      code: ELanguage.grk,
    },
    {
      name: "中文",
      code: ELanguage.zh,
    },
  ];

  const setLgMenu = (code: ELanguage) => {
    setLg(code);
    localStorage.setItem("lang", code);
    setOpen(false);
  };

  return (
    <>
      <ul className="py-2 px-4 flex flex-col gap-2">
        {lgMenu.map((item, index) => (
          <li
            className="text-center text-xl font-bold text-t-grey hover:text-white"
            key={index}
          >
            <button onClick={() => setLgMenu(item.code)}>{item.name}</button>
          </li>
        ))}
      </ul>
      <div className="absolute top-4 left-4">
        <Button
          onClick={() => setMenuStage("Home")}
          classes="px-4 top-0 right-0"
          padding={false}
          end={false}
        >
          <img className="w-[15px] h-[15px]" src={backIcon} alt="back" />
        </Button>
      </div>
    </>
  );
}
