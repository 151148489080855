import cls from "classnames";
import { motion } from 'framer-motion';

import WhiteButton from "./WhiteButton";
import Logo from "./Logo";
import useGetBalance from "../hooks/useGetBalance";
import {useEffect, useState} from "react";

interface IProps {
  className?: string,
  handlerOpenModalGeneralLink: () => void,
  children?: React.ReactNode;
}

function HeaderAccount({
  className,
  handlerOpenModalGeneralLink,
  children
}: IProps) {
  const [balance, setBalance] = useState<number>(0);

  const UpdateBalance = async () => {
    const balance = await useGetBalance();
    setBalance(balance);
  };

  useEffect(() => {
    UpdateBalance();
  }, []);

  return (
    <motion.div
      initial={{translateY: -50}}
      animate={{translateY: 0}}
      transition={{delay: .5, duration: .5}}
      className={cls(
        'flex flex-wrap gap-x-[64px] gap-y-[16px] w-full justify-between mb-[48px]',
        className
      )}
    >
      <Logo width="75" />
      <WhiteButton
        onClick={handlerOpenModalGeneralLink}
      >
        GENERATE LINK
      </WhiteButton>

      <div className='flex items-center justify-center bg-white p-[12px] text-center rounded-lg text-violet-600 text-xs sm:text-lg md:text-2xl'>
        Balance
        &nbsp;
        <b>{balance}</b>
      </div>

      <motion.div className="flex-wrap gap-[16px] hidden lg:flex">
        <WhiteButton
          to="/user/link-manager"
          isNavLink
          className="text-sm font-medium"
          big={false}
        >
          Link manager
        </WhiteButton>
        <WhiteButton
          to="/user/dashboard"
          isNavLink
          className="text-sm font-medium"
          big={false}
        >
          Dashboard
        </WhiteButton>
        <WhiteButton
          to="/user/payouts"
          isNavLink
          className="text-sm font-medium"
          big={false}
        >
          Payouts
        </WhiteButton>
        <WhiteButton
          to="/user/faq"
          isNavLink
          className="text-sm font-medium"
          big={false}
        >
          FAQ
        </WhiteButton>
      </motion.div>

      {children}
    </motion.div>
  )
}

export default HeaderAccount;
