import logo from "../assets/img/logo.png";
import {NavLink} from "react-router-dom";

interface Props {
  width?: string;
}

export default function Logo({ width }: Props) {
  return (
    <NavLink to="/" className="relative overflow-hidden">
      <div className="absolute -top-[100px] -left-[40px] w-[200px] h-[200px] bg-logo"></div>
      <img className="shadow-2xl" src={logo} alt="логотип" width={width} />
    </NavLink>
  );
}
