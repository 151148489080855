import {NavLink} from "react-router-dom";

export default function MenuHome({
  menu,
  setMenuStage,
}: {
  menu: { name: string, src?: string  }[];
  setMenuStage: (stage: string) => void;
}) {
  return (
    <ul className="py-2 px-4 flex flex-col gap-2">
      {menu.map((item, index) => (
        <li
          className="text-center text-xl font-bold text-t-grey hover:text-white"
          key={index}
        >
          {!item.src ? (
            <button
              onClick={() => {
                setMenuStage(item.name);
              }}
            >
              {item.name}
            </button>
            ) : (
              <NavLink to={item.src}>{item.name}</NavLink>
            )}
        </li>
      ))}
    </ul>
  );
}
