import {WALLET_ADAPTERS} from "@web3auth/base";

const config: Record<string, any> = {
  google: [
    WALLET_ADAPTERS.OPENLOGIN,
    {
      loginProvider: 'google',
    }
  ],
  apple: [
    WALLET_ADAPTERS.OPENLOGIN,
    {
      loginProvider: 'apple',
    }
  ],
  facebook: [
    WALLET_ADAPTERS.OPENLOGIN,
    {
      loginProvider: 'facebook',
    }
  ],
  metamask: [ WALLET_ADAPTERS.METAMASK ],
  coinbase: [ WALLET_ADAPTERS.COINBASE ],
  walletConnect: [ WALLET_ADAPTERS.WALLET_CONNECT_V2 ]
}

export default config;
