import cls from "classnames";
import { motion } from "framer-motion";
import {ISettingsNotification} from "../../interfaces/interface";

interface IProps {
  open: boolean,
  settings: ISettingsNotification,
  closeModalNotification: () => void;
  className?: string
}

enum MessageType {
  ERROR = 0,
  SUCCESS = 1,
  WARNING = 2,
  DEFAULT = 3
}

const classNamesMessageType = {
  [MessageType.ERROR]: 'bg-red-600 shadow-[0_0_15px_5px_rgba(255,93,93,1)]',
  [MessageType.SUCCESS]: 'bg-green-600 shadow-[0_0_15px_5px_rgba(47,249,55,1)]',
  [MessageType.WARNING]: 'bg-yellow-500 shadow-[0_0_15px_5px_rgba(239,203,93,1)]',
  [MessageType.DEFAULT]: 'bg-white shadow-[0_0_15px_5px_rgba(255,255,255,1)] text-black',
};

const variantsShow = {
  hidden: { opacity: 0 },
  show: { opacity: 1, y: 200 },
}

export default function NotificationModal({
  open = false,
  settings,
  closeModalNotification,
  className = ''
}: IProps) {
  return (
    <motion.div
      className={cls(
        'z-30 fixed top-[-100px]',
        'h-[80px] w-[250px] p-5 rounded-2xl bg-body',
        'flex items-center justify-center',
        `${classNamesMessageType[settings.typeMessage as MessageType]}`,
        className
      )}
      animate={open ? "show" : "hidden"}
      variants={variantsShow}
      transition={{ duration: settings.duration || 1.5 }}
      onAnimationComplete={closeModalNotification}
    >
      {settings.message}
    </motion.div>
  )
}
