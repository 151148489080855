import { createSlice } from '@reduxjs/toolkit';
import { IUser } from '../interfaces/user';

const initialState: IUser = {
    token: '',
    _id: '',
    wallet_address: '',
    email: '',
    earnings_percent: 0,
    is_approved: false,
    is_banned: false,
    referees: [],
    links: [],
    createdAt: '',
    updatedAt: '',
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser(state, action) {
            state.token = action.payload.token;
            state._id = action.payload._id;
            state.wallet_address = action.payload.wallet_address;
            state.email = action.payload.email;
            state.earnings_percent = action.payload.earnings_percent;
            state.is_approved = action.payload.is_approved;
            state.is_banned = action.payload.is_banned;
            state.referees = action.payload.referees;
            state.links = action.payload.links;
            state.createdAt = action.payload.createdAt;
            state.updatedAt = action.payload.updatedAt;
        },
        removeUser(state) {
            state.token = initialState.token;
            state._id = initialState._id;
            state.wallet_address = initialState.wallet_address;
            state.email = initialState.email;
            state.earnings_percent = initialState.earnings_percent;
            state.is_approved = initialState.is_approved;
            state.is_banned = initialState.is_banned;
            state.referees = initialState.referees;
            state.links = initialState.links;
            state.createdAt = initialState.createdAt;
            state.updatedAt = initialState.updatedAt;
        }
    }
});

export const { setUser, removeUser } = userSlice.actions;

export default userSlice.reducer;
