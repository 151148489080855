import React from "react";

type TProps = any;
type TState = { hasError: boolean; error: any; info: any };

export class ErrorBoundary extends React.Component<TProps, TState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null, info: null };
  }

  static getDerivedStateFromError(error: any) {
    console.log("ErrorBoundary setState");
    return { hasError: true, error, info: "" };
  }

  componentDidCatch(error: any, info: any) {
    console.log("ErrorBoundary: ", error, info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      console.log("ErrorBoundary render error", this.state.error);

      return (
        <>
          <h1>Что-то пошло не так.</h1>
          <p>{this.state.error.toString()}</p>
          {this.state.error.hasOwnProperty("stack") && (
            <p>{this.state.error.stack.toString()}</p>
          )}
        </>
      );
    }

    return this.props.children;
  }
}
