import cls from "classnames";
import Button from "../../components/Button";
import iconCopy from "../../assets/img/icon/copy.svg";
import iconMoonpay from "../../assets/img/icon/moonpay.svg";
import iconMatic from "../../assets/img/icon/crypto-logo.svg";
import { useEffect, useState } from "react";
import getAccounts from '../../hooks/useGetAccounts';
import useGetBalance from '../../hooks/useGetBalance';

interface IModalProps {
  isOpen: boolean,
  closeModalMoon: () => void;
}

export default function ModalMoon({ isOpen = false, closeModalMoon }: IModalProps) {
  const [balance, setBalance] = useState<string | undefined>();
  const [address, setAddress] = useState<string>("");
  const [isCopied, setIsCopied] = useState(false);

  const UpdateBalance = async () => {
    const balance = await useGetBalance();

    setBalance(balance);
  };

  const UpdateAddress = async () => {
    const address = await getAccounts();

    setAddress(address);
  }

  const updateDataUser = () => {
    UpdateBalance();
    UpdateAddress();
  }

  useEffect(() => updateDataUser(), [isOpen]);
  useEffect(() => updateDataUser(), []);

  const copyAddress = () => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 500);
      })
      .catch((error) => {
        console.error("Error copying address to clipboard:", error);
      });
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black/60 flex justify-center items-center z-50">
      <div
        className={cls("", {
          "flex flex-col justify-center items-center gap-2": true,
          "w-[75vw] rounded-[20px]": true,
          "bg-body": true,
          "p-6": true,
          "sm:w-[65vw]": true,
          "md:w-[55vw]": true,
          "lg:w-auto": true,
        })}
      >
        {/* Close button */}
        <div className="flex justify-between w-full mb-4">
          <img src={iconMoonpay} alt="" width={120} />
          <button className="self-end" onClick={closeModalMoon}>
            X
          </button>
        </div>

        {/* Address */}
        <div className="flex justify-between items-center gap-4 w-full border border-border rounded-lg pl-2 border-r-0">
          <span
            className={cls("w-[80%] overflow-hidden text-ellipsis", {
              "text-t-green": isCopied,
            })}
          >
            {address.slice(0, 6)} . . . {address.slice(-4)}
          </span>
          <Button padding={false} onClick={copyAddress}>
            <img src={iconCopy} alt="" width={24} height={24} />
          </Button>
        </div>

        {/* Balance */}
        <div className="flex items-center justify-between space-x-2 gap-1 py-2">
          <button
            className="text-3xl text-white transition-all active:-rotate-180"
            onClick={UpdateBalance}
          >
            &#8634;
          </button>
          <img
            className="h-3 w-3 relative top-[5px]"
            src={iconMatic}
            alt="matic"
          />
          {balance &&
            <p className="items-center barlow-semi-condensed-bold text-3xl">
              {Number(balance).toFixed(2)}
            </p>
          }
        </div>

        {/* Buy and sell buttons */}
        <div
          className={cls("flex flex-col gap-2 w-full", {
            "sm:flex-row": true,
          })}
        >
          <Button full={true} end={false} padding={false}>
            <a
              className="w-full"
              href="https://buy.moonpay.com/?apiKey=pk_live_CSPPhuxtvIf3PuLfvrzn9RBYLRi9zt&baseCurrencyCode=matic&baseCurrencyAmount=50&mpSdk=%7B%22version%22%3A%221.8.0%22%2C%22environment%22%3A%22production%22%2C%22flow%22%3A%22sell%22%2C%22variant%22%3A%22newTab%22%2C%22platform%22%3A%22web%22%7D"
              target="_blank"
              rel="noreferrer"
            >
              Buy
            </a>
          </Button>
          <Button full={true} padding={false} end={false}>
            <a
              className="w-full"
              href="https://sell.moonpay.com/?apiKey=pk_live_CSPPhuxtvIf3PuLfvrzn9RBYLRi9zt"
              target="_blank"
              rel="noreferrer"
            >
              Sell
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
}
