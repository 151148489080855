import cls from "classnames";
import { motion } from "framer-motion";
import Web3 from 'web3';
import {FieldValues, useForm} from "react-hook-form";
import Input from "../form/Input";
import WhiteButton from "../WhiteButton";
import {useContext} from "react";
import {Web3AuthContext} from "../../app/providers/ProviderWeb3Auth";

interface FormState extends FieldValues{
  transferWallet: string,
  amount: string,
}

interface IProps {
  open: boolean,
  className?: string,
  onClick: () => void,
}

const variantsOpen = {
  open: { translateY: 0 },
  closed: { translateY: -50 },
}
const variantsShow = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
}

const amountRegex = /^\d+(?:[.]\d+)?$/;
const walletRegex = /^0x[0-9a-zA-Z]{2,}$/;

function TransferModal ({
  open = false,
  onClick,
  className = ''
}: IProps) {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
  } = useForm<FormState>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      amount: '',
    },
  });

  const { provider, walletAddress } = useContext(Web3AuthContext);

  const sendEth = async ({ transfer_wallet, amount }: { transfer_wallet: string, amount: string }) => {
    if (!provider) {
      throw new Error('Transaction failed because provider is null');
    }

    const web3 = new Web3(provider);

    if (!web3.utils.isAddress(transfer_wallet)) {
      throw new Error("Invalid recipient address");
    }

    const value = Number(web3.utils.toWei(amount, 'ether'));

    return await web3.eth.sendTransaction({
      from: walletAddress,
      to: transfer_wallet,
      value,
      gas: 21000,
      gasLimit: value / 10 ** 12
    })
    .then((receipt) => {
      console.log('receipt: ', receipt);
    })
    .catch((error) => {
      alert(error);
      console.error('Transaction failed:', error);
    });
  };

  const submit = (formData: FormState) => {
    // window.RPC.sendTransaction(data); // Internal JSON-RPC error
    sendEth({
      transfer_wallet: formData.transferWallet,
      amount: formData.amount
    });
  };

  return (
    <motion.div
      className={cls(
        'fixed inset-0 bg-black/60 px-[24px] z-10',
        {'hidden': !open}
      )}
      animate={open ? "show" : "hidden"}
      variants={variantsShow}
    >
      <div
        className={cls(
          'fixed top-[50%] left-[50%] bg-body',
          'w-[calc(100%-32px)] lg:w-full max-w-[500px] md:w-[60%] rounded-[20px] bg-black',
          'translate-y-[-50%] translate-x-[-50%]'
        )}
      >
        <motion.div className={cls(
            'justify-center w-full h-full',
            'px-[24px] lg:px-[48px] pt-[16px] pb-[32px]',
            className,
          )}
          animate={open ? "open" : "closed"}
          variants={variantsOpen}
          transition={{delay: .3, duration: .5}}
        >
          <motion.div
            className={cls('flex flex-col items-center')}
          >
            <h1 className='uppercase text-xl lg:text-3xl mb-[32px] lg:mb-[80px]'>Transfer</h1>
            <div className='flex flex-col item-center gap-x-[24px] w-full'>
              <Input
                className='w-full mb-[32px]'
                label='Transfer wallet'
                name='transferWallet'
                register={register}
                error={errors.transferWallet}
                watch={watch}
                required={watch('transferWallet')?.length ? 'Please set transferWallet' : false}
                pattern={{value: walletRegex, message: 'Incorrect format'}}
              />
              <Input
                className='mb-[32px]'
                label='Amount'
                name='amount'
                register={register}
                error={errors.amount}
                watch={watch}
                required={watch('amount') ? 'Please set amount' : false}
                pattern={{ value: amountRegex, message: 'Incorrect amount value' }}
              />
              <WhiteButton
                className='mt-[64px] mx-auto text-lg'
                onClick={handleSubmit(submit)}
                disabled={!watch('amount')}
              >
                Request
              </WhiteButton>
            </div>
            <button
              className={cls(
                'absolute top-[10px] right-0',
                'size-9 lg:size-12 bg-white rounded-full',
                'border-violet-900 border-2',
                'text-2xl text-violet-900 cursor-pointer',
                'hover:text-white hover:bg-black transition-all duration-300',
                'translate-x-[0%] translate-y-[-50%]'
              )}
              onClick={() => {
                onClick();
                reset();
              }}
            >X</button>
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  )
}

export default TransferModal;
