import { ELanguage } from "../interfaces/interface";
import Button from "./Button";

import backIcon from "../assets/img/icon/back-arrow-white-icon.png";

export default function MenuPool({
  setOpen,
  setMenuStage,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMenuStage: React.Dispatch<React.SetStateAction<string>>;
}) {
  const closeMenu = (code: ELanguage) => {
    setOpen(false);
  };

  return (
    <>
      <ul className="py-2 px-4 flex flex-col gap-2"></ul>
      <div className="absolute top-4 left-4">
        <Button
          onClick={() => setMenuStage("Home")}
          classes="px-4 top-0 right-0"
          padding={false}
          end={false}
        >
          <img className="w-[15px] h-[15px]" src={backIcon} alt="back" />
        </Button>
      </div>
    </>
  );
}
