import type { IProvider } from "@web3auth/base";
import Web3 from "web3";
import { contractABI, contractAddress } from "../../utils/contract";

const RPC = (provider: IProvider) => {
  const web3 = new Web3(provider);

  console.log(web3, provider)

  const getChainId = async () => {
    // Get the connected Chain's ID
    return await web3.eth.getChainId()
      .then((chainId) => (chainId.toString()))
      .catch();
  }
  const getAccounts = async () => {
    // Get user's Ethereum public address
    return await web3.eth.getAccounts()
      .then((accounts) => (accounts[0]))
      .catch(() => {
        console.error("Error getting accounts!");
      });
  }
  const getBalance = async () => {
    // Get user's Ethereum public address
    const address = (await web3.eth.getAccounts());

    // Get user's balance in ether
    return web3.utils.fromWei(
      await web3.eth.getBalance(address[0]), // Balance is in wei
      "ether"
    );
  }
  const sendTransaction = async ({ transfer_wallet, amount }: { transfer_wallet: string, amount: string }) => {
    // Get user's Ethereum public address
    const fromAddress = (await web3.eth.getAccounts())[0];

    const amountWei = web3.utils.toWei(amount, "ether"); // Convert 1 ether to wei
    let transaction = {
      from: fromAddress,
      to: transfer_wallet,
      gas: 21000,
      value: amountWei,
    };

    // calculate gas transaction before sending
    transaction = {
      ...transaction,
      gas: await web3.eth.estimateGas(transaction),
    } as any;

    // Submit transaction to the blockchain and wait for it to be mined
    const receipt = await web3.eth.sendTransaction(transaction);

    return toStringJson(receipt);
  }
  const signMessage = async () => {
    // Get user's Ethereum public address
    const fromAddress = (await web3.eth.getAccounts())[0];

    const originalMessage = "YOUR_MESSAGE";

    // Sign the message
    const signedMessage = await web3.eth.personal.sign(
      originalMessage,
      fromAddress,
      "test password!" // configure your own password here.
    );

    return signedMessage;
  }
  const getPrivateKey = async () => {
    const privateKey = await provider.request({
      method: "eth_private_key",
    });

    return privateKey;
  }
  const toStringJson = (data: any) => {
    // can't serialize a BigInt, so this hack
    return JSON.parse(
      JSON.stringify(
        data,
        (key, value) => (typeof value === "bigint" ? value.toString() : value) // return everything else unchanged
      )
    );
  }
  const makeTrade = async (
    gameAddress: string,
    poolId: string,
    avatarUrl: string,
    countryCode: string,
    upOrDown: boolean,
    whiteLabelId: string,
    amountMatic: any
  ) => {
    try {
      console.log("makeTrade");

      console.log("это провадер в web3", provider);
      console.log("это сам web3", web3);
      const address = (await web3.eth.getAccounts())[0];
      const contract = new web3.eth.Contract(
        JSON.parse(JSON.stringify(contractABI)),
        contractAddress
      );
      const amountWei = web3.utils.toWei(amountMatic.toString(), "ether");
      // console.log(contract.methods);
      const tradeData = {
        poolId: poolId,
        avatarUrl: avatarUrl,
        countryCode: countryCode,
        upOrDown: upOrDown,
        whiteLabelId: whiteLabelId,
      };
      console.log("FFFFFFFFFFFFFFFFFFFf", tradeData, amountWei, address);

      const makeTradeTx = await contract.methods
        .makeTrade(tradeData)
        .send({ value: amountWei })
        .on("error", (error) => console.error(error));

      console.log(makeTradeTx);
      return toStringJson(makeTradeTx);
    } catch (error) {
      return error as string;
    }
  }

  return {
    provider,
    web3,
    getChainId,
    getAccounts,
    getBalance,
    sendTransaction,
    signMessage,
    getPrivateKey,
    toStringJson,
    makeTrade,
  }
}

export default RPC;
