import buttonUp from "../assets/img/button-up.png";
import buttonUpActive from "../assets/img/button-up-click.png";
import buttonDown from "../assets/img/button-down.png";
import buttonDownActive from "../assets/img/button-down-click.png";
import arrowUp from "../assets/img/icon/arrow-up.svg";
import arrowDown from "../assets/img/icon/arrow-down.svg";
import { TSideTeam } from "../interfaces/interface";

import cls from "classnames";
import {useRef, useState} from "react";

interface Props {
  textInfo: string;
  team: TSideTeam;
  selectedBet: number | null;
  onClick: () => void;
  selectedTeam: TSideTeam | null;
  setSelectedTeam?: React.Dispatch<React.SetStateAction<TSideTeam | null>>;
  isPlacedBet: boolean;
  disabledButton: boolean;
}

export enum EAltText {
  down = "кнопка уменьшения ставки",
  up = "кнопка увеличения ставки",
}

export enum EImages {
  down = "кнопка уменьшения ставки",
  up = "кнопка увеличения ставки",
}

const arImg = [
  [buttonDownActive, buttonDown],
  [buttonUpActive, buttonUp],
]

let idTimer: NodeJS.Timeout | number = 0;
const timeDelayForTransaction = 1000;

export default function ButtonBet({
  textInfo,
  team,
  selectedBet,
  selectedTeam,
  setSelectedTeam,
  onClick,
  isPlacedBet,
  disabledButton,
}: Props) {
  const [isShadow, setIsShadow] = useState(false);
  const teamIsUp = team === 'UP';
  const buttonRef = useRef<HTMLButtonElement>(null);

  const funcResetTimer = () => {
    setSelectedTeam && setSelectedTeam(team);
    onClick();

    setIsShadow(false);

    if (idTimer) {
      clearTimeout(idTimer);
      idTimer = 0;
    }
  }

  const handlerMouseDown = () => {
    setIsShadow(true);

    idTimer = setTimeout(() => funcResetTimer(), timeDelayForTransaction);
  }

  return (
    <button
      ref={buttonRef}
      className={cls(
        "relative h-[50px] xs:h-[75px] sm:h-[95px] md:h-auto",
        `before:content-[''] before:absolute before:top-[10px] before:left-0 before:h-[calc(100%-10px)] before:bg-body before:opacity-50 before:transition-[width] before:duration-[1000ms]`,
        {
          "opacity-100 pointer-events-auto": !disabledButton,
          'opacity-60 pointer-events-none': disabledButton,
          'before:opacity-50 before:w-full': isShadow,
          'before:opacity-0 before:w-0': !isShadow
      })}
      onClick={handlerMouseDown}
      disabled={disabledButton}
    >
      <span className="hidden xs:block absolute top-[10%] left-1/2 -translate-x-1/2 text-[7px]">
        {textInfo.toUpperCase()}
      </span>

      <>
        <img
          className="w-full h-full"
          src={arImg[Number(teamIsUp)][Number(disabledButton)]}
          alt={teamIsUp ? EAltText.up : EAltText.down}
        />
        <span
          className={cls(
      "w-full absolute bottom-[15%] flex justify-center items-center gap-2 text-[#013F16]",
            "whitespace-nowrap text-sm",
            "sm:text-base md:text-lg"
          )}
        >
          GO {team} <img src={teamIsUp ? arrowUp : arrowDown} alt={`arrow ${team}`} />
        </span>
      </>
    </button>
  );
}
