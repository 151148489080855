import WrapperTeamsMobile from "../teams/WrapperTeamsMobile";
import Dashboard from "./Dashboard";
import ListBettingButtons from "./listBettingButtons";
import Chart from "./chart/Chart";
import { TSideTeam } from "../../interfaces/interface";

import cls from "classnames";

interface IProps {
  placeBet: (side: TSideTeam) => void;
  selectedBet: number | null;
  setSelectedBet: React.Dispatch<React.SetStateAction<number>>;
  selectedTeam: TSideTeam | null;
  setSelectedTeam: React.Dispatch<React.SetStateAction<TSideTeam | null>>;
  isPlacedBet: boolean;
  disabledButton: boolean;
  setDisabledButton: (state: boolean) => void;
}

export default function Main({
  placeBet,
  selectedBet,
  setSelectedBet,
  selectedTeam,
  setSelectedTeam,
  isPlacedBet,
  disabledButton,
  setDisabledButton,
}: IProps) {
  return (
    <div className={cls("flex-grow")}>
      <div className=" h-full flex flex-col">
        <Chart />
        <Dashboard />
        <WrapperTeamsMobile
          placeBet={placeBet}
          selectedBet={selectedBet}
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          isPlacedBet={isPlacedBet}
          disabledButton={disabledButton}
          setDisabledButton={setDisabledButton}
        />
        <ListBettingButtons
          selectedBet={selectedBet}
          setSelectedBet={setSelectedBet}
          isPlacedBet={isPlacedBet}
          disabledButton={disabledButton}
        />
      </div>
    </div>
  );
}
