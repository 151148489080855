import { useContext, useState } from "react";

import { language as leng } from "../../utils/language";
import {
  IJackpotInfo,
  ILastResults,
  ILgState,
} from "../../interfaces/interface";
import { getSumWei, getRandomOnline } from "../../utils/helpers";

import {
  jackpotInfoContext,
  lastResultsContext,
  lgContext,
} from "../../app/providers/Providers";

import arrowUp from "../../assets/img/icon/arrow-green.svg";
import arrowDown from "../../assets/img/icon/arrow-red.svg";

import DashItem from "./DashItem";
import CoinSum from "../CoinSum";

export default function Dashboard() {
  const { lg } = useContext<ILgState>(lgContext);
  const LastResults: ILastResults = useContext(lastResultsContext);
  const JackpotInfo: IJackpotInfo = useContext(jackpotInfoContext);

  const [randomNumber, setRandomNumber] = useState(() =>
    getRandomOnline(5, 20)
  );

  return (
    <div className="flex justify-around text-t-grey text-[8px] px-2 py-1">
      <DashItem text={leng[lg].dashboard.jackpot.toUpperCase()}>
        <span className="bg-text-gold bg-clip-text text-transparent font-bold text-sm">
          {getSumWei(JackpotInfo?.JackpotBalance).toFixed(2)}
        </span>
      </DashItem>

      <DashItem text={leng[lg].dashboard.players.toUpperCase()}>
        <span className="text-white font-bold text-sm">{randomNumber}</span>
      </DashItem>

      <DashItem text={leng[lg].dashboard.payouts.toUpperCase()}>
        <CoinSum
          sum="9,909"
          color="text-t-green"
          fontSize="text-sm"
          size={14}
        />
      </DashItem>

      <DashItem text={leng[lg].dashboard.win.toUpperCase()}>
        <div className="flex gap-1">
          {LastResults?.Data.slice(0, 3).map((item: any, index: number) => {
            return (
              <img
                key={index}
                className=""
                src={item.Wins === "UP" ? arrowUp : arrowDown}
                alt="стрелка"
              />
            );
          })}
        </div>
      </DashItem>
    </div>
  );
}
