import {Web3AuthNoModal} from "@web3auth/no-modal";
import {web3AuthNoModalConfig} from "../../utils/web3AuthConfig";
import {ADAPTER_EVENTS, IProvider} from "@web3auth/base";
import Adapters from "./adapters";
import React from "react";

interface IProps {
  setWeb3Connected: React.Dispatch<React.SetStateAction<boolean>>
}

const Web3Auth = async ({ setWeb3Connected } : IProps) => {
  const web3Auth = new Web3AuthNoModal(web3AuthNoModalConfig);

  web3Auth.on(ADAPTER_EVENTS.ERRORED, (error) => {
      console.log("Error ADAPTER_EVENTS: ", error);
      // window.location.reload();
  });
  web3Auth.on(ADAPTER_EVENTS.CONNECTED, (data: any) => {
    console.log("connected to wallet", data);
    setWeb3Connected && setWeb3Connected(true);
    // web3Auth.provider will be available here after user is connected
  });

  // init adapters
  await Adapters(web3Auth);

  await web3Auth.init();

  return {
    web3Auth,
    provider: web3Auth.provider,
  }
}

export default Web3Auth;
