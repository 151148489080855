import {Web3AuthNoModal} from "@web3auth/no-modal";
import {removeStorage} from "../../../../utils/localStorage";

interface ILogout {
  web3Auth: Web3AuthNoModal | null,
}

const Logout = async ({ web3Auth }: ILogout) => {
  if (web3Auth) {
    await web3Auth
      .logout()
      .catch(error => console.log('Error Logout: ', error));

    removeStorage('wallet_connect');
    removeStorage();

    window.location.reload();
  }
};

export default Logout;
