import {setStorage} from "../utils/localStorage";

const getAccounts = () => {
  return window.RPC.getAccounts()
    .then((accounts: string) => {
      if (accounts) {
        setStorage('wallet_connect', accounts);
      }

      return accounts;
    })
    .catch((err: any) => console.error(err));
};

export default getAccounts;
