import React, {createContext, useState} from "react";
import {IGlobalSettings} from "../store/interfaces/globalSettings";

const initialState: IGlobalSettings = {
  web3Auth: null,
  provider: null,
  isConnected: false,
  walletAddress: '',
}

export const Web3AuthContext = createContext<IGlobalSettings>(initialState);

const ProviderWeb3Auth = ({ children }: { children: React.ReactNode }) => {
  const [web3Auth, setWeb3Auth] = useState(initialState.web3Auth);
  const [provider, setProvider] = useState(initialState.provider);
  const [isConnected, setWeb3Connected] = useState(initialState.isConnected);
  const [walletAddress, setWalletAddress] = useState(initialState.walletAddress);

  const value = {
    web3Auth,
    provider,
    isConnected,
    walletAddress,
    setWeb3Auth,
    setProvider,
    setWeb3Connected,
    setWalletAddress
  }

  return (
    <Web3AuthContext.Provider value={value}>
      {children}
    </Web3AuthContext.Provider>
  )
}

export default ProviderWeb3Auth;
