import cls from "classnames";
import { useState } from "react";
import MenuHome from "./MenuHome";
import MenuLg from "./MenuLg";
import MenuPool from "./MenuPool";
import MenuGames from "./MenuGames";
import { useAuth } from "../hooks/useAuth";

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Menu({ open, setOpen }: IProps) {
  const [menuStage, setMenuStage] = useState("Home");

  const menu = [
    {
      name: "Home",
    },
    {
      name: "Pools",
    },
    {
      name: "Games",
    },
    {
      name: "Languages",
    },
    {
      name: "Affiliate",
      src: useAuth() ? '/user/link-manager' : '/login',
    },
    {
      name: "Technical support",
      src: 'https://telegtam.org',
    },
  ];

  return (
    <div
      className={cls(
        "absolute bg-menu-transparent top-[56px] right-0 h-[calc(100vh-56px)] w-full z-30 flex flex-col items-center justify-center",
        {
          hidden: !open,
          "md:top-[86px] md:h-[calc(100vh-86px)]": true,
        }
      )}
    >
      {menuStage === "Home" && (
        <MenuHome menu={menu} setMenuStage={setMenuStage} />
      )}

      {menuStage === "Languages" && (
        <MenuLg setOpen={setOpen} setMenuStage={setMenuStage} />
      )}
      {menuStage === "Pools" && (
        <MenuPool setOpen={setOpen} setMenuStage={setMenuStage} />
      )}
      {menuStage === "Games" && (
        <MenuGames setOpen={setOpen} setMenuStage={setMenuStage} />
      )}
    </div>
  );
}
