import cls from "classnames";

interface Props {
  children: React.ReactNode;
  onClick?: () => void;
  classes?: string;
  end?: boolean;
  full?: boolean;
  hidden?: string;
  padding?: boolean;
  isConnected: boolean;
}

export default function Button({
  children,
  onClick,
  classes = '',
  end = true,
  full = false,
  hidden = "",
  padding = true,
  isConnected = false,
}: Props) {
  return (
    <div
      className={cls(
        `${hidden} bg-button rounded-lg px-[0.5px] pt-[0.5px] pb-[1px]`,
        {
          "self-end": end,
          "w-full": full,
        }
      )}
    >
      <div className={cls("bg-body rounded-lg pr-[0.5px] w-full relative")}>
        <span className={cls("absolute top-1 right-4 w-16 h-3 backdrop-blur-lg",
          {
            "bg-buttonConnected": isConnected,
            "bg-amber-600": !isConnected,
          }
        )} />
        <span className={cls("absolute bottom-1 left-4 w-20 h-3 backdrop-blur-lg",
          {
            "bg-buttonConnected": isConnected,
            "bg-yellow-300": !isConnected,
          }
        )} />
        <button
          disabled={isConnected}
          onClick={onClick}
          className={cls(`relative w-full text-xs flex justify-center items-center gap-1 rounded-lg overflow-hidden z-10 xs:text-sm bg-transparent backdrop-blur-lg ${classes} text-nowrap`, {
            "p-2 pr-5": padding,
            "p-1": !padding,
            "sm:p-3 sm:pr-6": padding,
            "sm:p-2": !padding,
            "bg-[#2ff9373d]": isConnected,
            "bg-button": !isConnected,
          })}
        >
          {children}
        </button>
      </div>
    </div>
  );
}
