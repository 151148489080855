import {CoinbaseAdapter} from "@web3auth/coinbase-adapter";
import config from './config';
import {useSelector} from "react-redux";

const Adapter = async (web3auth: { configureAdapter: (arg0: CoinbaseAdapter) => void; }) => {
  const adapter = new CoinbaseAdapter(config);
  web3auth.configureAdapter(adapter);
}

export default Adapter;
