import {motion} from "framer-motion";
import cls from "classnames";
import WhiteButton from "../WhiteButton";
import {deleteInviteLink, viewProfile} from '../../app/api/affiliate';
import {setUser} from "../../app/store/slices/userSlice";
import {useDispatch} from "react-redux";
import iconCopy from "../../assets/img/icon/copy.svg";
import Button from "../Button";

interface IProps {
  data: string[],
  index?: number,
  className?: string,
  classNameRow?: string,
  setUpdateLink?: (state: string) => void,
  callBack?: () => void,
}

const classTextRow = 'text-xs sm:text-lg md:text-2xl text-center w-[20%]';
const copyHandle = (event: any) => {
  navigator.clipboard.writeText(event.currentTarget.dataset.ref);
};

function RowTable({
  data,
  index = 0,
  className = '',
  classNameRow = '',
  setUpdateLink,
  callBack
}: IProps) {
  const dispatch = useDispatch();

  const handlerDeleteInviteLink = (handle: string) => {
    deleteInviteLink({ handle })
      .then((response) => {
        // console.log('response delete invite link: ', response);

        return viewProfile();
      })
      .then((response) => {
        // console.log('response view profile: ', response.data);

        if (!response.data.success) {
          throw new Error('Error in view profile!');
        }

        dispatch(
          setUser({...response.data.profile})
        );

        setUpdateLink && setUpdateLink(`delete_${handle}`);
      })
      .catch((error) => {
        throw new Error('Error in delete invite link: ', error);
      })
  };

  return (
    <motion.div
      className={cls('flex justify-between gap-x-[16px] py-[12px]', className)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: (index / 10 + 1), duration: 1 }}
    >
      <div className={cls('flex py-[12px] justify-between w-[90%]')}>
        {data.map((item, index) => (
          index !== 1
            ? (
              <p
                key={item + index}
                className={cls(classTextRow, classNameRow)}
              >
                {item}
              </p>
            ) : (
              <Button
                key={item + index}
                padding={false}
                onClick={(event) => {
                  copyHandle(event);
                  callBack && callBack();
                }}
                data-ref={item}
                classes='z-0'
              >
                Copy link
                <img src={iconCopy} alt="" width={24} height={24} />
              </Button>
            )
        ))}
      </div>
      <WhiteButton big={false} onClick={() => handlerDeleteInviteLink(data[0])}>Delete</WhiteButton>
    </motion.div>
  )
}

export default RowTable;
