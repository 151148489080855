interface IProps {
  text: string;
  children: React.ReactNode;
}

export default function DashItem({ text, children }: IProps) {
  return (
    <div className="flex flex-col items-center text-center justify-between max-w-[25%] min-w-[22%]">
      <p className="text-t-grey">{text}</p>
      {children}
    </div>
  );
}
