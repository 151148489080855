import { CHAIN_NAMESPACES } from "@web3auth/base";

export const APP_URL = "https://polygon.playfomo.io";
export const APP_NAME = "FOMO";

// clientId web3auth:
export const CLIENT_ID = process.env.WEB3AUTH_CLIENT_ID || "BBaLclLeSFcg7qmk7LHwjuay02e_f76otRDyK2Lf_SOQ4XmhModVIrStU-9Gn_9KriiCQ4J4ZhwIqi95rJfdoBc";
// "BBaLclLeSFcg7qmk7LHwjuay02e_f76otRDyK2Lf_SOQ4XmhModVIrStU-9Gn_9KriiCQ4J4ZhwIqi95rJfdoBc";

// wallet connect project id
export const WALLET_CONNECT_PROJECT_ID = process.env.WALLET_CONNECT_PROJECT_ID || "7262873324b131c5e7eee6c6ab833f1f";
//"7262873324b131c5e7eee6c6ab833f1f";

// upvsdown
// export const urlWebSocket = "wss://dev-api-polygonscan.playfomo.io/ws";

export const fee = 10; // 10 % комиссия

// //testnet
// export const POOL_ID = "0x12305b6789";
// export const ADDRESS_CONTRACT = "0x5d73576A696916a6c1018bc40E915E89C13cE0C2";
// export const RPC_PROVIDER_ADDRESS = "https://rpc-amoy.polygon.technology";
// export const BLOCK_EXPLORER_URL = "https://amoy.polygonscan.com";
// export const CHAIN_ID = "0x13882";
// export const DISPLAY_NAME = "Amoy";
// export const urlWebSocket = "wss://api-amoy.polygonscan.playfomo.io/ws";

// Polygon Testnet
// export const chainConfig = {
//   chainNamespace: CHAIN_NAMESPACES.EIP155,
//   chainId: "0x13882", // Please use 0x1 for Mainnet
//   rpcTarget: "https://polygon-amoy.blockpi.network/v1/rpc/public",
//   displayName: "Polygon Testnet",
//   blockExplorerUrl: BLOCK_EXPLORER_URL,
//   ticker: "MATIC",
//   tickerName: "MATIC",
//   logo: "https://cryptologos.cc/logos/polygon-matic-logo.png",
// };

//mainnet
export const POOL_ID = "0x11105b6789";
export const ADDRESS_CONTRACT = "0x281d56e015cb23cbb84fd9cc6b5a5faa877f9e25";
export const RPC_PROVIDER_ADDRESS = "https://polygon-rpc.com";
export const BLOCK_EXPLORER_URL = "https://polygonscan.com";
export const CHAIN_ID = "0x89";
export const DISPLAY_NAME = "Polygon Mainnet";
export const urlWebSocket = "wss://api-polygonscan.playfomo.io/ws";

// Polygon Mainnet
export const chainConfig = {
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: CHAIN_ID, // Please use 0x1 for Mainnet
  rpcTarget: RPC_PROVIDER_ADDRESS,
  displayName: DISPLAY_NAME,
  blockExplorerUrl: BLOCK_EXPLORER_URL,
  ticker: "MATIC",
  tickerName: "MATIC",
  logo: "https://cryptologos.cc/logos/polygon-matic-logo.png",
};
