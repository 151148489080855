/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { fee } from "../../utils/config";
import { language as leng } from "../../utils/language";
import {
  EStatusText,
  IInternalInfo,
  ILgState,
  IPrice,
  IRoundPool,
  TSideTeam,
} from "../../interfaces/interface";
import {
  internalInfoContext,
  lastPriceContext,
  lgContext,
  poolInfoContext,
  startPriceContext,
} from "../../app/providers/Providers";
import CoinSum from "../CoinSum";
import ButtonBet from "../ButtonBet";
import ListAvatarMobile from "./ListAvatarMobile";
import bgWin from "../../assets/img/bg-win.png";
import bgLose from "../../assets/img/bg-lose.png";
import iconWin from "../../assets/img/icon/icon-win.svg";
import iconLose from "../../assets/img/icon/icon-lose.svg";

import cls from "classnames";
import { getSumWei } from "../../utils/helpers";

interface IProps {
  team: TSideTeam;
  placeBet: () => void;
  selectedBet: number | null;
  selectedTeam: TSideTeam | null;
  setSelectedTeam?: React.Dispatch<React.SetStateAction<TSideTeam | null>>;
  isPlacedBet: boolean;
  disabledButton: boolean;
  setDisabledButton: (state: boolean) => void;
}

export default function TeamMobile({
  team,
  placeBet,
  selectedBet,
  selectedTeam,
  setSelectedTeam,
  isPlacedBet,
  disabledButton,
  setDisabledButton,
}: IProps) {
  const { lg } = useContext<ILgState>(lgContext);
  const PoolInfo: IRoundPool = useContext(poolInfoContext);
  const InternalInfo: IInternalInfo = useContext(internalInfoContext);
  const lastPrice = useContext<IPrice>(lastPriceContext);
  const startPrice = useContext<IPrice>(startPriceContext);
  const [showWiners, setShowWiners] = useState<{
    show: boolean;
    win: TSideTeam | null;
  }>({ show: false, win: null });

  useEffect(() => {
    if (
      InternalInfo.Text === EStatusText.Distribution &&
      startPrice !== 0 &&
      !showWiners.show
    ) {
      setShowWiners({
        show: true,
        win: lastPrice > startPrice ? "UP" : "DOWN",
      });
    } else if (
      InternalInfo.Text !== EStatusText.Distribution &&
      startPrice === 0 &&
      showWiners.show
    ) {
      setShowWiners({ show: false, win: null });
    }

    setDisabledButton(
        InternalInfo.Text === EStatusText.CloseTrades
        || InternalInfo.Text !== EStatusText.Open
    );
  }, [InternalInfo.Text]);

  const winOrLose = (win: TSideTeam) => {
    return (
      <div className="w-full h-full relative flex justify-center items-center">
        <img
          className="absolute w-full h-full object-cover scale-125"
          src={win === team ? bgWin : bgLose}
          alt="фон"
        />
        <div
          className={cls("absolute w-full h-full scale-125", {
            "bg-gradient-to-r to-[rgba(255,50,50,0.3)] from-transparent":
              team === "DOWN",
            "bg-gradient-to-l from-[rgba(145,255,93,0.3)] to-transparent":
              team === "UP",
          })}
        ></div>
        <div className="relative flex flex-col justify-end items-center gap-2 pb-3 rounded-2xl backdrop-blur-[59px] w-[70%] h-[80px]">
          {win === team ? (
            <>
              <img
                className=" absolute -top-10 left-1/2 translate-x-[-50%]"
                src={iconWin}
                alt="звезды"
              />
              <p className="bg-text-gold bg-clip-text text-transparent font-bold">
                {PoolInfo?.UpBetGroup?.Addresses?.length} WINNERS
              </p>
              <p className="text-t-green">
                {/* TODO тут поменять toFixed(ничего вместо 2) */}
                {(
                  (getSumWei(PoolInfo?.DownBetGroup.Total) +
                    getSumWei(PoolInfo?.UpBetGroup.Total)) *
                  (1 - fee / 100)
                ).toFixed(2)}
              </p>
            </>
          ) : (
            <>
              <div className="flex gap-1 absolute -top-4 left-1/2 translate-x-[-50%] items-end">
                <img
                  className="-rotate-12"
                  src={iconLose}
                  alt="звезды"
                  width={18}
                />
                <img src={iconLose} alt="звезды" width={28} />
                <img
                  className="rotate-12"
                  src={iconLose}
                  alt="звезды"
                  width={18}
                />
              </div>
              <p className="text-t-red font-bold">
                {team === "UP"
                  ? PoolInfo?.UpBetGroup.Bets.length
                  : PoolInfo?.DownBetGroup.Bets.length}{" "}
                LOSERS
              </p>
              <p className="text-t-red">0</p>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={cls("flex flex-col p-2.5 text-xs overflow-hidden h-[122px]", {
        "xs:h-[147px]": true,
        "sm:h-[167px]": true,
      })}
    >
      {InternalInfo.Text !== EStatusText.Distribution && (
        <>
          <div className="flex justify-between mb-1">
            <div
              className={cls("text-t-grey flex gap-1", {
                "order-last": team === "DOWN",
              })}
            >
              {leng[lg].teams.players}:
              {team === "UP" && (
                <span className="text-white">
                  {PoolInfo?.UpBetGroup?.Addresses?.length}
                </span>
              )}
              {team === "DOWN" && (
                <span className="text-white">
                  {PoolInfo?.DownBetGroup?.Addresses?.length}
                </span>
              )}
            </div>
            <CoinSum
              sum={
                team === "DOWN"
                  ? getSumWei(PoolInfo?.DownBetGroup?.Total)
                  : getSumWei(PoolInfo?.UpBetGroup?.Total)
              }
              color={team === "DOWN" ? "text-t-red" : "text-t-green"}
              size={14}
            />
          </div>
          <ListAvatarMobile team={team} />
          <ButtonBet
            disabledButton={disabledButton}
            textInfo={"your bet: 0000"}
            team={team}
            onClick={placeBet}
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
            selectedBet={selectedBet}
            isPlacedBet={isPlacedBet}
          />
        </>
      )}
      {showWiners.show &&
        showWiners.win &&
        InternalInfo.EnableTrade !== true &&
        winOrLose(showWiners.win)}
    </div>
  );
}
