import cls from "classnames";
import IconTotal from '../assets/img/icon/earning_stats.png';
import IconToday from '../assets/img/icon/coins_stack.png';

interface IProps {
  title: string,
  balance: string,
  up?: boolean,
  className?: string,
}

function EarningCard({ title, balance, up, className}: IProps) {
  return (
      <div
          className={cls(
              'flex flex-row flex-wrap justify-around sm:flex-col p-[8px] md:p-[16px] lg:p-[24px] gap-[8px] md:gap-[16px]',
              'border border-2 border-stone-600 bg-[#18191e] max-w-[600px] min-w-[320px] w-full md:w-[35%] rounded-2xl',
              className
          )}
      >
        <div className='flex justify-center'>
          <img className='h-[48px] md:h-[72px] w-[48px] md:w-[72px] lg:h-[120px] lg:w-[120px]' src={up ? IconTotal : IconToday} alt='icon' />
        </div>
        <div className='flex items-center justify-center'>
          <svg className='mr-[8px] h-[24px] w-[24px] md:h-[48px] md:w-[48px] lg:h-[64px] lg:w-[64px]' viewBox="0 0 25.773 42.955" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M23.254 34.828c-1.679 1.806-3.94 2.994-6.784 3.566v1.28c0 .982-.295 1.774-.884 2.377-.59.6-1.355.904-2.292.904-.938 0-1.7-.303-2.291-.904-.589-.603-.884-1.395-.884-2.378v-1.09c-3.359-.443-6.233-1.473-8.623-3.09-.543-.348-.93-.743-1.155-1.188C.113 33.86 0 33.275 0 32.545c0-.95.264-1.774.793-2.47.53-.699 1.157-1.047 1.884-1.047.363 0 .726.063 1.088.19.363.128.833.364 1.407.714 1.33.76 2.571 1.299 3.72 1.616 1.15.318 2.375.475 3.676.475 1.604 0 2.82-.277 3.653-.831.832-.556 1.248-1.386 1.248-2.497a2.06 2.06 0 0 0-.749-1.64c-.5-.427-1.12-.767-1.86-1.021-.742-.254-1.822-.572-3.245-.95-2.269-.603-4.128-1.214-5.58-1.83-1.453-.62-2.708-1.586-3.766-2.901-1.06-1.316-1.59-3.082-1.59-5.301 0-2.535.847-4.737 2.542-6.608 1.694-1.869 3.932-3.106 6.716-3.707l.012-1.781c0-.951.27-1.411.876-2.028C11.43.309 12.187 0 13.095 0c.937 0 1.7.301 2.29.904.59.602.917 1.07.917 2.052l-.012 1.639c2.51.41 4.96 1.409 7.35 2.994.544.38.93.8 1.156 1.26.228.458.34 1.022.34 1.688 0 .95-.27 1.775-.816 2.471-.545.698-1.179 1.046-1.904 1.046-.365 0-.712-.063-1.045-.19-.333-.127-.816-.365-1.452-.714-.212-.126-.68-.388-1.407-.783a11.271 11.271 0 0 0-2.337-.951 9.604 9.604 0 0 0-2.653-.358c-1.362 0-2.467.31-3.314.928-.846.619-1.27 1.45-1.27 2.496 0 1.014.484 1.775 1.452 2.282.968.507 2.496 1.013 4.584 1.52 2.238.54 4.075 1.11 5.512 1.712 1.436.603 2.677 1.568 3.721 2.9 1.044 1.33 1.566 3.122 1.566 5.371 0 2.567-.84 4.755-2.519 6.56"
              fill="#87d009" fillRule="evenodd"></path>
          </svg>

          <p className='text-[#88d00b] text-center text-2xl md:text-3xl lg:text-5xl'>{balance}</p>
        </div>
        <p className='w-full text-center text-[#8e8d8c] text-xl md:text-3xl lg:text-5xl'>{title}</p>
      </div>
  );
}

export default EarningCard;
