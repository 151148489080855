import { createSlice } from '@reduxjs/toolkit';
import {IGlobalSettings} from "../interfaces/globalSettings";

const initialState: IGlobalSettings = {
  web3Auth: null,
  provider: null,
  isConnected: false,
  walletAddress: '',
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setWeb3Auth(state, action) {
      state.web3Auth = action.payload;
    },
    setProvider(state, action) {
      state.provider = action.payload;
    },
    setLoggedIn(state, action) {
      state.isConnected = action.payload;
    },
    setWalletAddress(state, action) {
      state.walletAddress = action.payload;
    },
  }
});

export const {
  setWeb3Auth,
  setProvider,
  setLoggedIn,
  setWalletAddress,
} = settingsSlice.actions;

export default settingsSlice.reducer;
