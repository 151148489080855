import { FunctionComponentElement, PropsWithChildren, ReactNode } from 'react';
import { FieldError, FieldValues, UseFormRegister, Path, UseFormWatch } from 'react-hook-form';
import cls from "classnames";

interface Props<T extends FieldValues> extends PropsWithChildren {
  name: Path<T>;
  type?: string;
  label?: ReactNode;
  placeholder?: string;
  className?: string;
  wrapperClassName?: string;
  error?: FieldError;
  register?: UseFormRegister<T>;
  watch?: UseFormWatch<T>;
  required?: string | boolean;
  requiredMark?: boolean;
  pattern?: { value: RegExp; message: string };
}

export default function Input<T extends FieldValues>({
  name,
  type = 'text',
  label,
  placeholder,
  className,
  wrapperClassName,
  error,
  register,
  required,
  pattern,
  children,
}: Props<T>): FunctionComponentElement<Props<T>> {
  return (
    <div className={cls('flex flex-col', className)}>
      <div className={cls('relative flex items-center justify-between w-full', wrapperClassName)}>
        {label && (
          <>
            <span className="text-white text-sm md:text-lg relative mr-[12px] md:mr-[30px]">
              {label}
            </span>
          </>
        )}
        {!children ? (
          <input
            id={name}
            className={cls('max-w-[50%] bg-transparent border-b border-white rounded-lg p-1')}
            type={type}
            placeholder={placeholder}
            {...(register ? register(name, {required, pattern}) : null)}
          />
        ) : (
          children
        )}
      </div>
      {error && <span className="text-sm mt-[8px]">{error.message}</span>}
    </div>
  );
}
