import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import settingsReducer from "./slices/globalSettingsSlice";
import earningReducer from "./slices/earningSlice";

const store = configureStore({
    reducer: {
        user: userReducer,
        settings: settingsReducer,
        earning: earningReducer
    }
});

export default store;
