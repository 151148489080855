import fon from "../../assets/img/bg-grad-fat.svg";
import cryptoLogo from "../../assets/img/icon/crypto-logo.svg";

import cls from "classnames";
import { useContext } from "react";
import { internalInfoContext, lgContext, poolInfoContext } from "../../app/providers/Providers";
import {
  EStatusText,
  IInternalInfo,
  IRoundPool,
  TSideTeam,
} from "../../interfaces/interface";
import { getPoolPayout, getSumWei, getTime } from "../../utils/helpers";
import { fee } from "../../utils/config";
import { language } from "../../utils/language";
import BorderWrapper from "../BorderWrapper";

interface IProps {
  selectedBet: number | null;
  isPlacedBet: boolean;
  setIsPlacedBet: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Panel({
  selectedBet,
  isPlacedBet,
  setIsPlacedBet,
}: IProps) {
  const InternalInfo: IInternalInfo = useContext(internalInfoContext);
  const poolInfo = useContext<IRoundPool>(poolInfoContext);
  const { lg } = useContext(lgContext);

  if (isPlacedBet && InternalInfo.Text === EStatusText.Distribution)
    setIsPlacedBet(false);

  const textInfo = (side: TSideTeam) => {
    let treasure, percent;
    const totalUp = getSumWei(poolInfo.UpBetGroup.Total);
    const totalDown = getSumWei(poolInfo.DownBetGroup.Total);
    if (side === "UP" && selectedBet) {
      treasure =
        selectedBet *
        getPoolPayout(totalDown, totalUp + selectedBet, fee, "kof");
      percent =
        totalUp !== 0 && totalDown !== 0
          ? (totalDown / totalUp) * 100 + 100 - 10
          : 200;
    } else if (side === "DOWN" && selectedBet) {
      treasure =
        selectedBet *
        getPoolPayout(totalUp, totalDown + selectedBet, fee, "kof");
      percent =
        totalUp !== 0 && totalDown !== 0
          ? (totalUp / totalDown) * 100 + 100 - 10
          : 200;
    } else {
      treasure = 0.0;
      percent = 200;
    }

    return (
      <div className="flex flex-col gap-1 justify-between min-w-[30%] max-w-[30%] overflow-hidden">
        {/* pool */}
        <div className="flex flex-col">
          <p
            className={cls("text-t-grey text-[8px]", {
              "sm:text-[8px]": true,
              "md:text-[8px]": true,
              "lg:text-xs": true,
              "self-end text-end": side === "DOWN",
            })}
          >
            {language[lg].gameStatus[
              side === "UP" ? "poolUp" : "poolDown"
            ].toUpperCase()}
          </p>
          <p
            className={cls("text-xl font-bold text-t-green", {
              "xs:text-2xl": true,
              "sm:text-3xl": true,
              "md:text-xl": true,
              "lg:text-2xl": true,
              "text-t-green": side === "UP",
              "text-t-red self-end": side === "DOWN",
            })}
          >
            {side === "DOWN" && (
              <>
                <span className="text-xs opacity-50">% </span>
                {parseInt(String(percent), 10)}
              </>
            )}
            {side === "UP" && (
              <>
                {parseInt(String(percent), 10)}
                <span className="text-xs opacity-50"> %</span>
              </>
            )}
          </p>
        </div>
        {/* инвестиции */}
        <div className="flex flex-col">
          <p
            className={cls("text-t-grey text-[10px]", {
              "sm:text-xs": true,
              "md:text-[8px]": true,
              "lg:text-xs": true,
              "self-end": side === "DOWN",
            })}
          >
            {language[lg].gameStatus.investment.toUpperCase()}
          </p>
          <span
            className={cls("flex items-center gap-1", {
              "self-end": side === "DOWN",
            })}
          >
            <img src={cryptoLogo} width={16} height={16} alt="логотип крипты" />
            <span
              className={cls("text-base font-medium text-white", {
                "sm:text-lg": true,
                "md:text-base": true,
              })}
            >
              {typeof selectedBet === "number" && typeof treasure === "number"
                ? treasure.toFixed(1)
                : 0}
            </span>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={cls("pt-2 px-3 h-[120px] max-w-full", {
        "xs:h-[130px]": true,
        "sm:h-[150px] sm:pt-3": true,
        "md:h-[120px]": true,
        "lg:h-[130px]": true,
      })}
    >
      <BorderWrapper rounded="rounded-xl" borderBottom={true}>
        <div
          className={cls(
            "absolute -bottom-0.5 left-1/2 -translate-x-1/2 w-[40%] h-[95%]",
            {
              "xl:w-[34%]": true,
            }
          )}
        >
          <div className="w-full h-full bg-body trapeciya rounded-t-2xl overflow-hidden">
            <img className=" object-cover h-[150%] w-screen" src={fon} alt="" />
          </div>
        </div>

        <div
          className={cls("h-full w-full flex justify-between p-2 ", {
            "sm:px-4 sm:py-3": true,
            "md:p-2": true,
            "lg:px-4 lg:py-3": true,
          })}
        >
          {/* Текст слева */}
          {textInfo("UP")}

          {/* Текст по центру */}
          <div
            className={cls(
              "flex-grow overflow-hidden h-full flex items-center justify-center pt-8 z-10",
              {
                "xl:pt-10": true,
              }
            )}
          >
            <p
              className={cls("text-[10px]", {
                "xs:text-lg text-center font-bold text-t-grey": true,
                "sm:text-2xl": true,
                "md:text-sm": true,
                "lg:text-lg": true,
              })}
            >
              {InternalInfo.Text === EStatusText.Open && (
                <>
                  <span
                    className={cls("text-xl text-t-green", {
                      "xs:text-2xl": true,
                      "sm:text-3xl": true,
                      "md:text-2xl": true,
                    })}
                  >
                    {getTime(InternalInfo.Time2End)}
                  </span>
                  <br />
                  <span
                    className={cls("text-[8px] whitespace-nowrap font-normal", {
                      "xs:text-[10px]": true,
                      "sm:text-xs": true,
                      "md:text-[10px]": true,
                    })}
                  >
                    {language[lg].gameStatus.seconds.toUpperCase()}
                  </span>
                </>
              )}

              {InternalInfo.Text === EStatusText.Mining &&
                language[lg].gameStatus.mining.toUpperCase()}

              {InternalInfo.Text === EStatusText.CloseTrades &&
                InternalInfo.Time2End === 0 &&
                language[lg].gameStatus.mining.toUpperCase()}
              {InternalInfo.Text === EStatusText.CloseTrades &&
                InternalInfo.Time2End > 0 && (
                  <span
                    className={cls("text-2xl text-t-red", {
                      "xs:text-3xl": true,
                      "md:text-2xl": true,
                    })}
                  >
                    {getTime(InternalInfo.Time2End)}
                  </span>
                )}
              {InternalInfo.Text === EStatusText.Distribution &&
                language[lg].gameStatus.distribution.toUpperCase()}
            </p>
          </div>
          {/* Текст справа */}
          {textInfo("DOWN")}
        </div>
      </BorderWrapper>
    </div>
  );
}
