
export enum EStatusText {
	Open = "TradesAreOpened",
	CloseTrades = "TradesAreClosedWaitFinishPrice",
	Mining = "WaitingForMining",
	Distribution = "TradesAreClosedDistributionTrades",
}

export enum ELanguage {
	ru = "ru",
	en = "en",
	es = "es",
	pt = "pt",
	grk = "grk",
	zh = "zh",
}

export interface ILgState {
	lg: ELanguage,
	setLg: (lang: ELanguage) => void
}

// "TradesAreOpened" | "TradesAreClosedWaitFinishPrice" | "WaitingForMining" | "TradesAreClosedDistributionTrades"
// export type TLanguage = 'ru' | 'en';
export type TAvatarKey = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
export type TSideTeam = 'UP' | 'DOWN';

export interface IRoundPool {
	Created: boolean,
	StartPrice: number,
	EndPrice: number,
	MinBetAmount: number,
	MaxBetAmount: number,
	PoolBetsLimit: number,
	UpBetGroup: {
		Bets: any[],
		Addresses: any[],
		Avatars: any[],
		Countries: any[],
		WhiteLabelIds: any[],
		Total: number,
		DistributedCount: number,
		TotalDistributed: number
	},
	DownBetGroup: {
		Bets: any[],
		Addresses: any[],
		Avatars: any[],
		Countries: any[],
		WhiteLabelIds: any[],
		Total: number,
		DistributedCount: number,
		TotalDistributed: number
	},
	RoundStartTime: number,
	TradesStartTimeMS: number,
	TradesEndTimeMS: number
}

export interface IPriceInfo {
	lastPrice: number,
	lastTime: string,
	lastTimeMs: number
}

export interface IInternalInfo {
	EnableTrade: boolean,
	Time2End: number,
	Text: EStatusText
}

export interface IJackpotInfo {
	JackpotBalance: number
}

export interface IResultGame {
	StartPrice: number,
	EndPrice: number,
	Wins: TSideTeam | null,
	Hash: string,
	Timestamp: number
}
export interface ILastResults {
	Data: IResultGame[]
}

export interface IGameStatus {
	PoolInfo: IRoundPool,
	PriceInfo: IPriceInfo,
	InternalInfo: IInternalInfo,
	JackpotInfo: IJackpotInfo,
	NewResult: IResultGame
	LastResults: ILastResults
}

export interface GameContextProps {
	gameStatus: IGameStatus
}

// export interface ISelectedBet {
// 	bet: number | null
// 	side: TSideTeam
// }

export type IPrice = number;

export interface ISettingsNotification {
	message: string,
	duration?: number,
	typeMessage?: 0 | 1 | 2 | 3 | number
}