import crypto from "../assets/img/icon/crypto-logo.svg";

interface Props {
  sum: string | number;
  color?: string;
  size?: number;
  font?: string;
  fontSize?: string;
}

export default function CoinSum({
  sum,
  color = "text-white",
  size = 16,
  font = "font-bold",
  fontSize = "text-xs",
}: Props) {
  return (
    <div className={`${color} ${font} ${fontSize} flex items-center gap-1`}>
      <img src={crypto} width={size} height={size} alt="крипта" /> {sum}
    </div>
  );
}
