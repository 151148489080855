import cls from "classnames";
import WhiteButton from "./WhiteButton";

interface IProps {
  className?: string,
}

const MobileMenuPages = ({ className = '' } :IProps) => {
  return (
    <div className={cls(
      'fixed bottom-[16px] left-0 lg:hidden w-full px-[16px]',
      'flex gap-[8px] justify-between',
      className
    )}>
      <WhiteButton
        to="/user/link-manager"
        isNavLink
        className="text-sm font-medium !w-[24%] max-w-max text-center flex-shrink-0"
        big={false}
      >
        Link manager
      </WhiteButton>
      <WhiteButton
        to="/user/dashboard"
        isNavLink
        className="text-sm font-medium !w-[25%] max-w-max text-center flex-shrink-0"
        big={false}
      >
        Dashboard
      </WhiteButton>
      <WhiteButton
        to="/user/payouts"
        isNavLink
        className="text-sm font-medium !w-[25%] max-w-max text-center flex-shrink-0"
        big={false}
      >
        Payouts
      </WhiteButton>
      <WhiteButton
        to="/user/faq"
        isNavLink
        className="text-sm font-medium !w-[25%] max-w-max text-center flex-shrink-0"
        big={false}
      >
        FAQ
      </WhiteButton>
    </div>
  )
}

export default MobileMenuPages;