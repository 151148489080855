import {MetamaskAdapter} from "@web3auth/metamask-adapter";
import config from "./config";
import {useSelector} from "react-redux";

const Adapter = (web3auth: { configureAdapter: (arg0: MetamaskAdapter) => void; }) => {
  const adapter = new MetamaskAdapter(config);
  web3auth.configureAdapter(adapter);
}

export default Adapter;
