import {
  APP_NAME,
  APP_URL,
  BLOCK_EXPLORER_URL,
  CHAIN_ID,
  CLIENT_ID,
  DISPLAY_NAME,
  RPC_PROVIDER_ADDRESS,
  chainConfig,
} from "./config";
import { WEB3AUTH_NETWORK, UX_MODE, CHAIN_NAMESPACES } from "@web3auth/base";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";

export const privateKeyProvider = new EthereumPrivateKeyProvider({
  config: { chainConfig },
});

export const web3AuthNoModalConfig = {
  clientId: CLIENT_ID,
  web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_MAINNET,
  privateKeyProvider,
  // uiConfig: {
  //   appName: APP_NAME,
  //   appUrl: APP_URL,
  //   logoLight: "https://web3auth.io/images/web3authlog.png",
  //   logoDark: "https://web3auth.io/images/web3authlogodark.png",
  //   defaultLanguage: "en",
  //   mode: "dark",
  //   theme: {
  //     primary: "#151719",
  //   },
  //   useLogoLoader: true,
  // },
};




//walletConnect index подключается в App
