import { useState, useEffect, useContext, useCallback } from "react";
import { ChartData } from "chart.js";
import {
  historyContext,
  startPriceContext,
  lastPriceContext,
} from "../app/providers/Providers";
import { IPrice } from "../interfaces/interface";

const maxChartPoints = 110;

const createInitialData = (history: any) => ({
  labels: history?.map((el: any) => ""),
  datasets: [
    {
      fill: true,
      label: "BTC Price",
      data: history?.map((el: any) => el.lastPrice / 10000),
      borderColor: "#fff",
      backgroundColor: "transparent",
    },
  ],
});

const useChartData = () => {
  const gameHistory = useContext<any[]>(historyContext);
  const startPrice = useContext<IPrice>(startPriceContext);
  const lastPrice = useContext<IPrice>(lastPriceContext);
  const [chartData, setChartData] = useState<ChartData<"line">>(
    createInitialData([])
  );
  const [lockValue, setLockValue] = useState<number | null>(null);

  console.log({
    startPrice,
    lastPrice
  });

  // TODO: отслеживание данных цены
  console.log({
    startPrice,
    lastPrice
  });

  useEffect(() => {
    if (gameHistory === undefined) return;
    setChartData(createInitialData(gameHistory));
  }, [gameHistory]);

  useEffect(() => {
    if (lastPrice === undefined && chartData.datasets[0].data.length === 0)
      return;

    if (
      startPrice !== 0 &&
      chartData.datasets[0].data.findIndex((el) => el === startPrice) !== -1
    ) {
      setLockValue(startPrice / 10000);
    } else {
      setLockValue(lastPrice / 10000);
    }
  }, [startPrice, lastPrice, chartData]);

  const updateData = useCallback(() => {
    setChartData((prevData: any) => {
      const data = prevData.datasets[0].data;
      const labels = prevData.labels;

      if (data.length === 100) {
        data.splice(
          89,
          11,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null
        );
        labels.push("", "", "", "", "", "", "", "", "", "");
      }

      if (data.length > maxChartPoints - 1) data.shift();
      if (labels.length > maxChartPoints - 1) labels.shift();
      labels.push("");
      data.splice(89, 0, lastPrice / 10000);

      return {
        ...prevData,
        labels: labels,
        datasets: [
          {
            ...prevData.datasets[0],
            data: data,
            borderColor: "rgba(255, 247, 46, 1)",
            pointStyle: "circle",
            pointRadius: function (context) {
              if (context.raw === startPrice) {
                return 3;
              } else {
                return context.dataIndex === 89 ? 3 : 0;
              }
            },
            pointBackgroundColor: "rgba(255, 247, 46, 1)",
          },
        ],
      };
    });
  }, [lastPrice, startPrice]);

  useEffect(() => {
    if (lastPrice === 0) return;
    updateData();
  }, [lastPrice, updateData]);

  return { chartData, lockValue, updateData, maxChartPoints };
};

export default useChartData;
