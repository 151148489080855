import cls from "classnames";
import { betsList } from "../../utils/constants";

interface IProps {
  selectedBet: number | null;
  setSelectedBet: React.Dispatch<React.SetStateAction<number>>;
  isPlacedBet: boolean;
  disabledButton: boolean;
}

export default function ListBettingButtons({
  selectedBet,
  setSelectedBet,
  isPlacedBet,
  disabledButton,
}: IProps) {
  return (
    <div className="flex justify-between gap-1 px-2 md:hidden">
      {betsList.map((bet) => (
        <button
          key={bet}
          className={cls(
            `flex-grow p-[1px] h-[40px] rounded-[7px] bg-border-green`,
            { "opacity-60": disabledButton }
          )}
          onClick={() => {
            setSelectedBet(bet);
          }}
          disabled={disabledButton}
        >
          <div className={`bg-body w-full h-full rounded-[7px]`}>
            <div
              className={cls(
                `font-bold text-xs flex justify-center items-center rounded-[7px] w-full h-full backdrop-blur-[39px] overflow-hidden`,
                {
                  "text-t-green": bet !== selectedBet,
                  "bg-betting-green text-body": bet === selectedBet,
                }
              )}
            >
              {bet}
            </div>
          </div>
        </button>
      ))}
    </div>
  );
}
