import { createSlice } from '@reduxjs/toolkit';
import { IEarnings } from '../interfaces/earning';

const initialState: IEarnings = {
    earnings: {
        direct: 0,
        referees: 0,
        total: 0
    },
    links: [
        {
            link_id: '',
            handle: '',
            txs: [
                {
                    amount: '',
                    hash: '',
                    sender_address: '',
                    __v: 0,
                    _id: ''
                }
            ]
        }
    ],
    referees: [
        {
            links: [
                {
                    link_id: '',
                    handle: '',
                    txs: [
                        {
                            amount: '',
                            hash: '',
                            sender_address: '',
                            __v: 0,
                            _id: ''
                        }
                    ]
                }
            ],
            wallet_address: ''
        }
    ]
}

const earningSlice = createSlice({
    name: 'earning',
    initialState,
    reducers: {
        setEarning(state, action) {
            state.earnings = action.payload.earnings;
            state.links = action.payload.links;
            state.referees = action.payload.referees;
        },
        removeEarning(state) {
            state.earnings = initialState.earnings;
            state.links = initialState.links;
            state.referees = initialState.referees;
        }
    }
});

export const { setEarning, removeEarning } = earningSlice.actions;

export default earningSlice.reducer;
