export const language = {
  en: {
    gameStatus: {
      open: "Bets",
      mining: "Waiting for mining",
      distribution: "Distributing payouts",
      poolUp: "Up payouts",
      poolDown: "Down payouts",
      investment: "treasury",
      seconds: "seconds left",
    },
    info: {
      mining: "Mining completed successfully",
      distribution: "money distributing complete",
    },
    dashboard: {
      players: "LIVE PLAYERS",
      jackpot: "JACKPOT",
      payouts: "ALL TIME PAID",
      win: "LAST 3 WIN",
    },
    teams: {
      players: "Players",
      up: {
        header: "Up Pool Treasury",
      },
      down: {
        header: "Down Pool Treasury",
      },
    },
    buttons: {
      menu: "MENU",
      connect: "CONNECT WALLET",
    },
  },
  ru: {
    gameStatus: {
      open: "Ставки",
      mining: "Ожидание майнинга",
      distribution: "Распределение выплат",
      poolUp: "Выплаты Up",
      poolDown: "Выплаты Down",
      investment: "Инвестиции",
      seconds: "секунд осталось",
    },
    info: {
      mining: "Майнинг завершен успешно",
      distribution: "Выплата выплачена",
    },
    dashboard: {
      players: "В СЕТИ",
      jackpot: "ДЖЕКПОТ",
      payouts: "ВСЕГО ОПЛАЧЕНО",
      win: "ПОСЛЕДНИЕ 3 ПОБЕДЫ",
    },
    teams: {
      players: "Игроки",
      up: {
        header: "Капитал Up",
      },
      down: {
        header: "Капитал Down",
      },
    },
    buttons: {
      menu: "МЕНЮ",
      connect: "ПОДКЛЮЧИТЬ КОШЕЛЕК",
    },
  },
  pt: {
    gameStatus: {
      open: "Apostas",
      mining: "Esperando mineração",
      distribution: "Distribuindo pagamentos",
      poolUp: "Pagamentos Up",
      poolDown: "Pagamentos Down",
      investment: "Tesouraria",
      seconds: "segundos restantes",
    },
    info: {
      mining: "Mineração completada com sucesso",
      distribution: "Distribuição de pagamentos concluída",
    },
    dashboard: {
      players: "JOGADORES ONLINE",
      jackpot: "JACKPOT",
      payouts: "PAGAMENTOS TOTAIS",
      win: "ÚLTIMAS 3 VITÓRIAS",
    },
    teams: {
      players: "Jogadores",
      up: {
        header: "Tesouraria Up",
      },
      down: {
        header: "Tesouraria Down",
      },
    },
    buttons: {
      menu: "MENU",
      connect: "CONECTAR CARTEIRA",
    },
  },
  grk: {
    gameStatus: {
      open: "Στοιχήματα",
      mining: "Αναμονή εξόρυξης",
      distribution: "Διανομή πληρωμών",
      poolUp: "Πληρωμές Up",
      poolDown: "Πληρωμές Down",
      investment: "Θησαυροφυλάκιο",
      seconds: "δευτερόλεπτα απομένουν",
    },
    info: {
      mining: "Η εξόρυξη ολοκληρώθηκε με επιτυχία",
      distribution: "Η διανομή των χρημάτων ολοκληρώθηκε",
    },
    dashboard: {
      players: "ΠΑΙΚΤΕΣ LIVE",
      jackpot: "ΤΖΑΚΠΟΤ",
      payouts: "ΣΥΝΟΛΙΚΕΣ ΠΛΗΡΩΜΕΣ",
      win: "ΤΕΛΕΥΤΑΙΕΣ 3 ΝΙΚΕΣ",
    },
    teams: {
      players: "Παίκτες",
      up: {
        header: "Θησαυροφυλάκιο Up",
      },
      down: {
        header: "Θησαυροφυλάκιο Down",
      },
    },
    buttons: {
      menu: "ΜΕΝΟΥ",
      connect: "ΣΥΝΔΕΣΗ ΠΟΡΤΟΦΟΛΙΟΥ",
    },
  },
  zh: {
    gameStatus: {
      open: "投注",
      mining: "等待挖矿",
      distribution: "分配奖金",
      poolUp: "Up 奖金",
      poolDown: "Down 奖金",
      investment: "财库",
      seconds: "剩余秒数",
    },
    info: {
      mining: "挖矿成功完成",
      distribution: "奖金分配完成",
    },
    dashboard: {
      players: "在线玩家",
      jackpot: "大奖池",
      payouts: "总支付金额",
      win: "最近3次胜利",
    },
    teams: {
      players: "玩家",
      up: {
        header: "Up 奖金池",
      },
      down: {
        header: "Down 奖金池",
      },
    },
    buttons: {
      menu: "菜单",
      connect: "连接钱包",
    },
  },
  es: {
    gameStatus: {
      open: "Apuestas",
      mining: "Esperando minado",
      distribution: "Distribuyendo recompensas",
      poolUp: "Pagos Up",
      poolDown: "Pagos Down",
      investment: "Tesorería",
      seconds: "segundos restantes",
    },
    info: {
      mining: "Minado completado exitosamente",
      distribution: "Distribución de recompensas completada",
    },
    dashboard: {
      players: "JUGADORES EN LINEA",
      jackpot: "JACKPOT",
      payouts: "RECOMPENSAS TOTALES",
      win: "ULTIMAS 3 VICTORIAS",
    },
    teams: {
      players: "Jugadores",
      up: {
        header: "Tesorería Up",
      },
      down: {
        header: "Tesorería Down",
      },
    },
    buttons: {
      menu: "MENÚ",
      connect: "CONECTAR BILLETERA",
    },
  },
};
