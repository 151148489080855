import Button from '../Button';
import iconGoogle from '../../assets/img/icon/google.svg';
import iconMetamask from '../../assets/img/icon/metamask.svg';
import iconCoinbase from '../../assets/img/icon/coinbase.svg';
import iconFacebook from '../../assets/img/icon/facebook.svg';
import iconApple from '../../assets/img/icon/apple.svg';
import iconWC from '../../assets/img/icon/wallet-connect.svg';
import {login} from '../../app/web3/authPlatform';
import cls from "classnames";
import { isMobile } from 'react-device-detect';
import {useContext} from 'react';
import {Web3AuthContext} from "../../app/providers/ProviderWeb3Auth";
import {addLinkInvitee} from "../../app/api/affiliate";
import {getDataForInviteeLink} from "../../utils/helpers";
import getAccounts from "../../hooks/useGetAccounts";

interface IProps {
  closeModalAuth: () => void;
}

export default function ModalAuth({ closeModalAuth }: IProps) {
  const { web3Auth, setWeb3Connected, setProvider, setWalletAddress} = useContext(Web3AuthContext);

  const handlerLogin = (platform: string) => {
    login({ web3Auth, platform })
      .then(async (web3authProvider) => {
        if (!web3authProvider) {
          throw new Error('Error method handlerLogin!');
        }

        setProvider && setProvider(web3authProvider);
        setWeb3Connected && setWeb3Connected(true);
        setWalletAddress && setWalletAddress(getAccounts().then(((account: string) => account)));
        closeModalAuth();

        const data = getDataForInviteeLink();

        if (data.invitee_address) {
          addLinkInvitee(data)
            .then((response) => {
              if (!response.data.success) {
                throw new Error('Error method add link invitee!');
              }
            })
            .catch((error) => {
              if (error.response.data.error) {
                alert(error.response.data.error);
                throw new Error(error.response.data.error);
              }
            });
        }
      })
      .catch((error) => console.log(error));
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black/60 flex justify-center items-center z-50">
      <div
        className={cls("", {
          "flex flex-col justify-center items-center gap-2": true,
          "w-[75vw] rounded-[20px]": true,
          "bg-body": true,
          "p-5": true,
          "sm:w-[65vw]": true,
          "md:w-[55vw]": true,
          "lg:w-[45vw]": true,
        })}
      >
        <button className="self-end" onClick={closeModalAuth}>
          X
        </button>
        <p>Connect social network</p>
        <Button
          onClick={() => handlerLogin('google')}
          classes="flex gap-2"
          end={false}
          full={true}
        >
          <img src={iconGoogle} alt="google" width={24} height={24} />
          Google
        </Button>
        <div className="w-full flex gap-2">
          <Button
            onClick={() => handlerLogin('facebook')}
            classes="flex gap-2"
            end={false}
            full={true}
          >
            <img src={iconFacebook} alt="facebook" width={24} height={24} />
            Facebook
          </Button>
          <Button
            onClick={() => handlerLogin('apple')}
            classes="flex gap-2"
            end={false}
            full={true}
          >
            <img src={iconApple} alt="apple" className="h-6" />
            Apple
          </Button>
        </div>
        <p>or</p>
        <Button onClick={() => handlerLogin('walletConnect')} end={false} full={true}>
          <img src={iconWC} alt="wallet connect" width={24} height={24} />
          WalletConnect
        </Button>
        <div className="flex gap-2 w-full">
          {!isMobile && (
            <Button onClick={() => handlerLogin('metamask')} end={false} full={true}>
              <img src={iconMetamask} alt="metamask" width={24} height={24} />
              Metamask
            </Button>
          )}
          {isMobile && !window.ethereum && (
            <Button
              onClick={() =>
                window.location.replace(
                  "https://metamask.app.link/dapp/polygon.playfomo.io"
                )
              }
              end={false}
              full={true}
            >
              <img src={iconMetamask} alt="metamask" width={24} height={24} />
              Metamask
            </Button>
          )}
          {isMobile && window.ethereum && (
            <Button onClick={() => handlerLogin('metamask')} end={false} full={true}>
              <img src={iconMetamask} alt="metamask" width={24} height={24} />
              Metamask
            </Button>
          )}

          <Button onClick={() => handlerLogin('coinbase')} end={false} full={true}>
            <img src={iconCoinbase} alt="coinbase" width={24} height={24} />
            Coinbase
          </Button>
        </div>
      </div>
    </div>
  );
}
