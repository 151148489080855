import {CHAIN_ID, CLIENT_ID, RPC_PROVIDER_ADDRESS} from "../../../../utils/config";
import {CHAIN_NAMESPACES, WEB3AUTH_NETWORK} from "@web3auth/base";

const config = {
  clientId: CLIENT_ID,
  sessionTime: 3600, // 1 hour in seconds
  chainConfig: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: CHAIN_ID,
    rpcTarget: RPC_PROVIDER_ADDRESS,
  },
  web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_MAINNET,
};

export default config;