import cls from "classnames";
import { language } from "../../utils/language";
import iconDown from "../../assets/img/icon/arrow-rose.svg";
import iconUp from "../../assets/img/icon/arrow-lime.svg";
import iconPlayers from "../../assets/img/icon/players.svg";
import { IRoundPool, TSideTeam } from "../../interfaces/interface";
import { lgContext, poolInfoContext } from "../../app/providers/Providers";
import { useContext } from "react";
import CoinSum from "../CoinSum";
import { getSumWei } from "../../utils/helpers";

interface IProps {
  team: TSideTeam;
}

export default function HeaderTeam({ team }: IProps) {
  const { lg } = useContext(lgContext);
  const PoolInfo = useContext<IRoundPool>(poolInfoContext);

  return (
    <div
      className={cls(
        "flex flex-col gap-2 w-full p-4 pb-2 relative overflow-hidden",
        {
          "bg-head-green": team === "UP",
          "bg-head-red": team === "DOWN",
        }
      )}
    >
      <img
        className={cls("absolute opacity-15 -z-50", {
          "translate-x-2/3 right-10 -top-20": team === "DOWN",
          "right-10 top-2": team === "UP",
        })}
        src={team === "UP" ? iconUp : iconDown}
        alt="стрела"
        width={150}
        height={150}
      />
      <div className="flex justify-between">
        <p
          className={cls("text-xs", {
            "text-t-green": team === "UP",
            "text-t-red": team === "DOWN",
          })}
        >
          {language[lg].teams[
            team === "UP" ? "up" : "down"
          ].header.toUpperCase()}
        </p>
        <img
          className=""
          src={team === "UP" ? iconUp : iconDown}
          alt="стрелка"
        />
      </div>
      <div className="flex justify-between items-center bg-head-white">
        {team === "UP" && (
          <CoinSum
            sum={getSumWei(PoolInfo.UpBetGroup.Total)}
            size={32}
            fontSize="text-2xl"
          />
        )}
        {team === "DOWN" && (
          <CoinSum
            sum={getSumWei(PoolInfo.DownBetGroup.Total)}
            size={32}
            fontSize="text-2xl"
          />
        )}
        <div className="flex flex-col">
          <p className="text-xs text-t-grey">{language[lg].teams.players}</p>
          <span className="flex gap-1">
            <img src={iconPlayers} alt="" />
            {team === "UP" && PoolInfo.UpBetGroup.Addresses.length}
            {team === "DOWN" && PoolInfo.DownBetGroup.Addresses.length}
          </span>
        </div>
      </div>
      <div className=" absolute left-0 bottom-0 h-[1px] bg-line-teams-head w-full"></div>
    </div>
  );
}
