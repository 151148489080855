import {FieldValues, useForm, Controller} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {signIn, signUp, viewProfile} from '../../app/api/affiliate/';
import {useNavigate} from 'react-router-dom';

import Input from "../form/Input";
import WhiteButton from "../WhiteButton";
import {getStorage, setStorage} from "../../utils/localStorage";
import {setUser} from "../../app/store/slices/userSlice";
import ButtonWallet from "../ButtonWallet";
import iconWallet from "../../assets/img/icon/wallet.svg";
import iconCheckWallet from "../../assets/img/icon/check.svg";
import {useContext} from "react";
import { lgContext } from "../../app/providers/Providers";
import { language } from "../../utils/language";

interface Props {
  openModalAuth: () => void,
  isConnected: boolean;
  className?: string;
}

interface FormState extends FieldValues {
  email: string;
  password: string;
  className?: string;
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export default function LoginForm({openModalAuth, isConnected, className = ''}: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lg } = useContext(lgContext);

  const {
    register,
    formState: {errors},
    control,
    watch,
    handleSubmit,
  } = useForm<FormState>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const handlerSignIn = (data: { wallet_address: string, email: string, password: string }) => {
    signIn(data)
      .then((response) => {
        // console.log('response sign in: ', response);

        setStorage('token', response.data.token);

        viewProfile()
          .then((response) => {
            // console.log('response view profile: ', response.data);

            if (!response.data.success) {
              throw new Error('Error in view profile!');
            }

            dispatch(
              setUser({ token: response.data.token, ...response.data.profile})
            );

            navigate('/user/link-manager');
          });
      })
      .catch((error) => {
        if (error) {
          throw new Error('Error in catch block sign in! ', error);
        } else {
          handlerSignUp(data);
        }
      })
  }

  const handlerSignUp = (data: { wallet_address: string, email: string, password: string }) => {
    signUp(data)
      .then((response) => {
        // console.log('response signUp: ', response.data);

        if (!response.data.success) {
          throw new Error('Error in sign up!');
        }

        return handlerSignIn(data);
      })
      .catch((error) => {
        if (error.response.data.error === 'User already exists') {
          // alert('User already exists');
          // console.log('User already exists')
          handlerSignIn(data);
        }
      })
  }

  const submit = (formData: FormState) => {
    const data = {
      wallet_address: getStorage('wallet_connect'),
      email: formData.email,
      password: formData.password,
    };

    getStorage()
      ? handlerSignIn(data)
      : handlerSignUp(data);
  };

  const disabledSubmit = Boolean(!getStorage('wallet_connect') || !watch('email').length || !watch('password').length);

  return (
    <div className={`flex flex-col items-center gap-x-[24px] ${className}`}>
      <div className='mb-[32px]'>
        <ButtonWallet onClick={openModalAuth} isConnected={isConnected}>
          <img src={isConnected ? iconCheckWallet : iconWallet} alt={isConnected ? "the wallet is connected" : "button wallet connected"} />
          {language[lg].buttons.connect.toUpperCase()}
        </ButtonWallet>
      </div>
      <Input
        className='w-full mb-[32px]'
        label='Email'
        name='email'
        type='email'
        watch={watch}
        register={register}
        error={errors.email}
        required={watch('email')?.length === 0 ? 'Please enter you email' : false}
        pattern={{value: emailRegex, message: 'Incorrect email'}}
      />
      <Controller
        name='password'
        control={control}
        render={() => (
          <Input
            className='w-full mb-[32px]'
            label='Password'
            name='password'
            watch={watch}
            error={errors.password}
            register={register}
            type='password'
            required={watch('password')?.length === 0 ? 'Please enter you password' : false}
          />
        )}
      />
      <WhiteButton
        className='mt-[32px] lg:mt-[64px] mx-auto text-lg'
        onClick={handleSubmit(submit)}
        disabled={disabledSubmit}
      >
        submission
      </WhiteButton>
    </div>
  );
}
