import {Outlet} from "react-router-dom";
import {useAuth} from "../hooks/useAuth";
import LoginPage from "./LoginPage";
import { useNavigate } from 'react-router-dom';
import {useEffect} from "react";

const PrivateRoute = () => {
  const isAuth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth) {
      navigate('/login', {  });
    }
  }, [])

  return isAuth
    ? <Outlet/>
    : <LoginPage />;
};

export default PrivateRoute;
