import { avatars } from "../../utils/constants";
import cls from "classnames";
import { TSideTeam } from "../../interfaces/interface";

interface Props {
  avatar?: string;
  classes?: string;
  where?: "mobile" | "desktop";
  team?: TSideTeam;
}

const checkIsUrlToImage = (avatarUrl: string = '') => (/(.+)\.(jpg|png)$/.test(avatarUrl));

export default function Avatar({
  avatar = '1',
  classes,
  where = "desktop",
  team,
}: Props): JSX.Element {
  const srcAvatar = !checkIsUrlToImage(avatar)
      // @ts-ignore
      ? avatars[avatar]
      : avatar;

  if (where === "mobile") {
    return (
      <div
        className={`${classes} rounded-full backdrop-blur-xl min-w-8 h-8 overflow-hidden bg-body`}
      >
        <div
          className={cls(
            `relative backdrop-blur-xl rounded-full overflow-hidden min-w-8 h-8`,
            {
              "bg-gradient-to-r from-ava-from-green to-ava-to-green":
                team === "UP",
              "bg-gradient-to-r from-ava-from-red to-ava-to-red":
                team === "DOWN",
            }
          )}
        >
          {avatar && (
            <img
              className="absolute top-0 left-0 w-full h-full"
              src={srcAvatar}
              alt="аватар"
              width={32}
              height={32}
            />
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`${classes} w-16 h-16 self-start rounded-lg backdrop-blur-xl overflow-hidden bg-body`}
      >
        <div
          className={cls(
            `relative backdrop-blur-xl rounded-lg overflow-hidden w-full h-full`,
            {
              "bg-gradient-to-r from-green-800 to-cyan-900": team === "UP",
              "bg-gradient-to-r from-rose-800 to-fuchsia-900": team === "DOWN",
            }
          )}
        >
          {avatar && (
            <img
              className="absolute bottom-0 left-1/2 translate-x-[-50%] w-full h-full"
              src={srcAvatar}
              alt="аватар"
            />
          )}
        </div>
      </div>
    );
  }
}
