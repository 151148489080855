import HeaderAccount from '../components/HeaderAccount';

import { motion } from 'framer-motion';
import { useState } from "react";
import {useSelector} from 'react-redux';
import GeneralLinkModal from "../components/modals/GeneralLinkModal";
import EarningCard from "../components/EarningCard";
import MobileMenuPages from "../components/MobileMenuPages";
import {formingCurrentNumberEarnings} from '../utils/helpers';

function DashboardPage() {
  const { earnings } = useSelector((state: any) => state.earning);
  const [openGeneralLinkModal, setOpenGeneralLinkModal] = useState(false);

  const handlerOpenModalGeneralLink = () => {
    setOpenGeneralLinkModal((prev) => !prev)
  }

  return (
    <div className='flex flex-col pt-[48px] h-full w-full bg-bg-violet-gradient bg-no-repeat bg-cover'>
      <motion.div
        className='flex flex-col items-center bg-bg-violet w-full mb-[64px] px-[16px] lg:px-[32px]'
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{delay: .5, duration: .5}}
      >
        <HeaderAccount
          handlerOpenModalGeneralLink={handlerOpenModalGeneralLink}
        />
        <h1 className='text-4xl md:text-6xl mb-[16px] md:mb-[32px] lg:mb-[48px]'>Dashboard</h1>
        <div
          className='flex flex-wrap justify-center gap-[16px] md:gap-[32px] mb-[48px] mx-auto px-[16px] md:px-[32px] lg:px-[48px] w-full '>
          <EarningCard
            title='EARNINGS TOTAL'
            balance={formingCurrentNumberEarnings(earnings.total)}
          />
          <EarningCard
            up
            title='Earnings Paid'
            balance={formingCurrentNumberEarnings(earnings.direct)}
          />
        </div>
      </motion.div>
      <GeneralLinkModal
        open={openGeneralLinkModal}
        onClick={handlerOpenModalGeneralLink}
      />
      <MobileMenuPages />
    </div>
  )
}

export default DashboardPage;
