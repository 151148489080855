/* eslint-disable react-hooks/exhaustive-deps */
import cls from "classnames";
import BorderWrapper from "../BorderWrapper";
import {
  EStatusText,
  IInternalInfo,
  IPrice,
  IRoundPool,
  TSideTeam,
} from "../../interfaces/interface";
import ListAvatarDesctop from "./ListAvatarDesctop";
import ButtonBet from "../ButtonBet";
import BettingButtons from "./BettingButtons";
import { useContext, useEffect, useState } from "react";
import bdgWin from "../../assets/img/bg-win-desk.png";
import iconWin from "../../assets/img/icon/icon-win.svg";
import bdgLose from "../../assets/img/bg-lose-desk.png";
import iconLose from "../../assets/img/icon/icon-lose.svg";
import HeaderTeam from "./HeaderTeam";
import {
  internalInfoContext,
  lastPriceContext,
  poolInfoContext,
  startPriceContext,
} from "../../app/providers/Providers";
import { getSumWei } from "../../utils/helpers";
import { fee } from "../../utils/config";

interface IProps {
  team: TSideTeam;
  placeBet: () => void;
  bets: number[];
  selectedBet: number | null;
  handleBet: (bet: number, side?: TSideTeam) => void;
  selectedTeam: TSideTeam | null;
  isPlacedBet: boolean;
  disabledButton: boolean;
  setDisabledButton: (state: boolean) => void;
  setSelectedTeam?: React.Dispatch<React.SetStateAction<TSideTeam | null>>;
}

export default function TeamDesktop({
  team,
  placeBet,
  bets,
  selectedBet,
  handleBet,
  selectedTeam,
  isPlacedBet,
  disabledButton,
  setDisabledButton,
  setSelectedTeam,
}: IProps) {
  const InternalInfo = useContext<IInternalInfo>(internalInfoContext);
  const lastPrice = useContext<IPrice>(lastPriceContext);
  const startPrice = useContext<IPrice>(startPriceContext);
  const poolInfo = useContext<IRoundPool>(poolInfoContext);
  const [showWinners, setShowWinners] = useState<{
    show: boolean;
    win: TSideTeam | null;
  }>({ show: false, win: null });

  useEffect(() => {
    if (
      InternalInfo.Text === EStatusText.Distribution &&
      startPrice !== 0 &&
      !showWinners.show
    ) {
      setShowWinners({
        show: true,
        win: lastPrice > startPrice ? "UP" : "DOWN",
      });
    } else if (
      InternalInfo.Text !== EStatusText.Distribution &&
      startPrice === 0 &&
      showWinners.show
    ) {
      setShowWinners({ show: false, win: null });
    }

    setDisabledButton(
      InternalInfo.Text === EStatusText.CloseTrades
      || InternalInfo.Text !== EStatusText.Open
    );

  }, [InternalInfo.Text]);

  const winOrLose = (win: TSideTeam) => {
    const prize =
      (getSumWei(poolInfo?.DownBetGroup.Total) +
        getSumWei(poolInfo?.UpBetGroup.Total)) *
      (1 - fee / 100);

    return (
      <div className="w-full h-full relative overflow-hidden">
        <img
          className="object-cover"
          src={win === team ? bdgWin : bdgLose}
          alt="фон"
        />
        <div
          className={cls(
            "absolute top-0 w-full h-full bg-gradient-to-b to-transparent",
            {
              "from-[rgba(145,255,93,0.3)]": team === "UP",
              "from-[rgba(255,50,50,0.3)]": team === "DOWN",
            }
          )}
        ></div>
        <div
          className={cls(
            "absolute top-24 -left-20 backdrop-blur-[59px] w-[calc(150%)] h-[150px] shadow-[0_0px_30px_-15px_rgba(0,0,0,1)]",
            {
              "rotate-12": team === "UP",
              "-rotate-12": team === "DOWN",
            }
          )}
        ></div>
        <div
          className={cls(
            "absolute top-28 -left-20 backdrop-blur-[59px] w-[calc(150%)] h-[120px] shadow-[0_0px_30px_-15px_rgba(0,0,0,1)]",
            {
              "rotate-12": team === "UP",
              "-rotate-12": team === "DOWN",
            }
          )}
        ></div>
        <div className="absolute top-[167px] w-full flex flex-col justify-center items-center gap-2 pb-3">
          {win === team && (
            <>
              <img
                className="absolute -top-[70px]"
                src={iconWin}
                alt="звезды"
              />
              <p className="bg-text-gold bg-clip-text text-transparent font-bold">
                {team === "UP"
                  ? poolInfo?.UpBetGroup.Bets.length
                  : poolInfo?.DownBetGroup.Bets.length}{" "}
                WINNERS
              </p>
              <p className="text-t-green">{prize.toFixed(2)}</p>
            </>
          )}
          {win !== team && (
            <>
              <div className="flex gap-1 absolute -top-[40px] items-end">
                <img
                  className="-rotate-12"
                  src={iconLose}
                  alt="звезды"
                  width={20}
                />
                <img src={iconLose} alt="звезды" width={30} />
                <img
                  className="rotate-12"
                  src={iconLose}
                  alt="звезды"
                  width={20}
                />
              </div>
              <p className="text-t-red font-bold">
                {team === "UP"
                  ? poolInfo?.UpBetGroup.Bets.length
                  : poolInfo?.DownBetGroup.Bets.length}{" "}
                LOSERS
              </p>
              <p className="text-t-red">{(0).toFixed(2)}</p>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={cls(
        "hidden w-[26%] max-w-[250px] h-[calc(100% - 12px)] mt-3",
        {
          "md:flex": true,
          "xl:max-w-[320px] xl:min-w-[300px]": true,
        }
      )}
    >
      <BorderWrapper rounded="rounded-xl">
        {!showWinners.show && (
          <>
            <HeaderTeam team={team} />
            <ListAvatarDesctop team={team} />
            <div
              className={cls("flex flex-col gap-1 p-2", {
                "opacity-50": selectedTeam !== team,
              })}
            >
              <ButtonBet
                disabledButton={disabledButton}
                onClick={placeBet}
                textInfo={"your bet: 0000"}
                team={team}
                selectedBet={selectedBet}
                selectedTeam={selectedTeam}
                setSelectedTeam={setSelectedTeam}
                isPlacedBet={isPlacedBet}
              />
              <BettingButtons
                disabledButton={disabledButton}
                handleBet={handleBet}
                selectedBet={selectedBet}
                team={team}
                bets={bets}
                selectedTeam={selectedTeam}
                isPlacedBet={isPlacedBet}
              />
            </div>
          </>
        )}
        {showWinners.show &&
          showWinners.win &&
          InternalInfo.EnableTrade !== true &&
          winOrLose(showWinners.win)}
      </BorderWrapper>
    </div>
  );
}
