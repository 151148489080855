import cls from "classnames";

interface Props {
  children: React.ReactNode;
  onClick?: (someVar: any) => void;
  classes?: string;
  end?: boolean;
  full?: boolean;
  wallet?: boolean;
  hidden?: string;
  padding?: boolean;
}

export default function Button({
  children,
  onClick,
  classes,
  end = true,
  full = false,
  wallet = false,
  hidden = "",
  padding = true,
  ...otherProps
}: Props) {
  return (
    <div
      className={cls(
        `${hidden} bg-border-button rounded-lg px-[0.5px] pt-[0.5px] pb-[1px]`,
        {
          "self-end": end,
          "w-full": full,
        }
      )}
    >
      <div className={cls("bg-body rounded-lg pr-[0.5px] w-full relative")}>
        <button
          onClick={onClick}
          className={cls(`z-10 relative overflow-hidden flex justify-center items-center gap-1 rounded-lg w-full ${classes} bg-button backdrop-blur-[400px] text-nowrap hover:backdrop-blur-xl hover:opacity-90 xs:text-sm text-xs`, {
            "p-2 pr-5": padding,
            "p-1": !padding,
            "sm:p-3 sm:pr-6": padding,
            "sm:p-2": !padding,
          })}
          {...otherProps}
        >
          {children}
        </button>
      </div>
    </div>
  );
}
