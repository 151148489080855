import { motion } from "framer-motion";
import HeaderTable from "./HeaderTable";
import RowTable from "./RowTable";
import cls from "classnames";

interface IProps {
  headers: string[],
  rows: string[][],
  className?: string,
  setUpdateLink?: (state: string) => void,
  callBack?: () => void,
}

function Table({
  className,
  headers,
  rows,
  setUpdateLink,
  callBack,
}: IProps) {
  return (
    <motion.div
      className={cls('w-full', className)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: .1, duration: .5 }}
    >
      <HeaderTable headers={headers} />
      {rows && rows.map((item, index) => <RowTable key={item[0]} data={item} index={index} callBack={callBack} setUpdateLink={setUpdateLink} />)}
    </motion.div>
  )
}
export default Table;
