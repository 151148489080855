import { TSideTeam } from "../../interfaces/interface";
import cls from "classnames";

interface IProps {
  team: TSideTeam;
  bets: number[];
  selectedBet: number | null;
  handleBet: (bet: number, side?: TSideTeam) => void;
  selectedTeam: TSideTeam | null;
  isPlacedBet: boolean;
  disabledButton: boolean;
}

export default function BettingButtons({
  team,
  bets,
  selectedBet,
  handleBet,
  selectedTeam,
  isPlacedBet,
  disabledButton,
}: IProps) {
  return (
    <div className="hidden justify-between flex-wrap gap-1 md:flex">
      {bets.map((bet) => (
        <button
          key={bet}
          className={cls(`flex-grow p-[1px] h-[40px] rounded-[7px]`, {
            "bg-border-green": team === "UP",
            "bg-border-red": team === "DOWN",
            "opacity-60": disabledButton,
          })}
          onClick={() => handleBet(bet, team)}
          disabled={disabledButton}
        >
          <div className={`bg-body w-full h-full rounded-[7px]`}>
            <div
              className={cls(
                `font-bold text-xs flex justify-center items-center rounded-[7px] w-full h-full backdrop-blur-[39px] overflow-hidden px-1`,
                {
                  "text-t-green":
                    (bet !== selectedBet && team === "UP") ||
                    (team === "UP" && selectedTeam !== team),
                  "text-t-red":
                    (bet !== selectedBet && team === "DOWN") ||
                    (team === "DOWN" && selectedTeam !== team),
                  "bg-betting-green text-body":
                    bet === selectedBet &&
                    team === "UP" &&
                    selectedTeam === team,
                  "bg-betting-red text-body":
                    bet === selectedBet &&
                    team === "DOWN" &&
                    selectedTeam === team,
                }
              )}
            >
              {bet}
            </div>
          </div>
        </button>
      ))}
    </div>
  );
}
