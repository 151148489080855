import HeaderAccount from '../components/HeaderAccount';

import { motion } from 'framer-motion';
import { useState, useMemo } from "react";
import {useSelector} from 'react-redux';
import GeneralLinkModal from "../components/modals/GeneralLinkModal";
import Table from "../components/table/Table";
import MobileMenuPages from "../components/MobileMenuPages";
import {ILink} from '../app/store/interfaces/user';
import NotificationModal from "../components/modals/NotificationModal";
import useModal from "../hooks/useModal";
import {ISettingsNotification} from "../interfaces/interface";

function LinkManagerPage() {
  const userData = useSelector((state: any) => state.user);
  const earningData = useSelector((state: any) => state.earning);
  
  const [createLink, setUpdateLink] = useState<string>('');
  const [openGeneralLinkModal, setOpenGeneralLinkModal] = useState(false);
  const [settingsNotification, setSettingsNotification] = useState<ISettingsNotification>({message: '', typeMessage: 0});

  const {
    isOpen: isOpenModalNotification,
    openModalHandler: openModalNotification,
    closeModalHandler: closeModalNotification,
  } = useModal();

  const handlerOpenModalGeneralLink = () => {
    setOpenGeneralLinkModal((prev) => !prev)
  }

  const handlerOpenModalMotification = () => {
    setSettingsNotification(
      { message: 'Your link has been copied!', typeMessage: 3, duration: 1 }
    );
    openModalNotification();
  }

  console.log('earningData ', earningData);

  const rows = useMemo(
    () => (
      userData.links.map((link: ILink) => (
        [
          link.handle,
          `${window.location.origin}/${link.handle}?ref=${userData.wallet_address}`,
          userData.earnings_percent,
          30,
        ]
      ))
    ), [createLink]);

  return (
    <>
      <div className='flex pt-[48px] h-full w-full bg-bg-violet-gradient bg-no-repeat bg-cover'>
        <motion.div
          className='flex flex-col items-center bg-bg-violet h-full w-full px-[16px] lg:px-[32px]'
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          transition={{delay: .5, duration: .5}}
        >
          <HeaderAccount
            handlerOpenModalGeneralLink={handlerOpenModalGeneralLink}
          />
          <h1 className='text-4xl md:text-6xl mb-[16px] md:mb-[32px] lg:mb-[48px]'>Link manager</h1>
          <Table
            headers={[
              'NAME',
              'LINK',
              'Referral percent',
              'EARNINGS TOTAL',
            ]}
            rows={rows}
            callBack={handlerOpenModalMotification}
            setUpdateLink={setUpdateLink}
          />
        </motion.div>
        <GeneralLinkModal
          open={openGeneralLinkModal}
          onClick={handlerOpenModalGeneralLink}
          setUpdateLink={setUpdateLink}
        />
        <MobileMenuPages />
      </div>
      <NotificationModal open={isOpenModalNotification} closeModalNotification={closeModalNotification} settings={settingsNotification} />
    </>
  )
}

export default LinkManagerPage;
